import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { properties } from "../../utils/Properties_es";
import { validateFieldStyle } from "../../utils/UtilsFunction";
import CustomModal from "./CustomModal";
import { useApp } from "../../hooks/useApp";

const SearchCustomerExternalApi = (props) => {
  const {
    authInfo,
    isLoading,
    setLoading,
    errorMsg,
    setErrorMsg,
    setSuccessMsg,
    modalData,
    setModalData,
  } = useApp();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: props.form,
    mode: "onChange",
  });
  const [applyStyleForm, setApplyStyleForm] = useState(false);

  const applyStyleToForm = () => {
    if (!applyStyleForm) {
      setApplyStyleForm(true);
    }
  };

  const onSubmit = async (data) => {
    await props.handleSearchCustomer(data);
  };

  return (
    <CustomModal defaultmodal={false}>
      <Modal
        show={props.isOpen}
        keyboard={false}
        size={props.modalSize}
        onHide={() => false}
        centered
      >
        <Modal.Header>
          <Modal.Title>Buscar datos de cliente</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div className="form-group mb-2">
              <label htmlFor="id">Identificación</label>
              <input
                type="text"
                className={`form-control ${validateFieldStyle(
                  applyStyleForm,
                  errors.id
                )}`}
                maxLength={7}
                name="id"
                id="id"
                {...register("id", {
                  required: true,
                })}
              />
              {errors.id && (
                <div className="invalid-feedback">
                  {properties.com_parval_label_field_required}
                </div>
              )}
            </div>
            <input name="cual" type="hidden" {...register("cual")} value="I" />
          </Modal.Body>
          <Modal.Footer>
            <div className="row">
              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                <button
                  className="btn btn-warning px-3 me-md-2"
                  id="actionModal"
                  onClick={handleSubmit(onSubmit)}
                  name="customerSearch"
                >
                  Buscar
                </button>

                <button
                  type="button"
                  className="btn btn-secondary"
                  id="cancelModal"
                  onClick={props.handleCancelModal}
                >
                  {properties.com_parval_label_button_cancel_title}
                </button>
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </CustomModal>
  );
};

export default SearchCustomerExternalApi;
