import { URL } from "../../utils/UrlConstant";
import { publicFetch } from "../../utils/CustomFetch";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constant";
import qs from "qs";
import { Buffer } from "buffer";
export const SVAPPloginRequest = async () => {
  let responseData = {};

  //auth header data
  const appClientId = process.env.REACT_APP_SECURITY_APPCLIENT_ID;
  const appClientPass = process.env.REACT_APP_SECURITY_APPCLIENT_PASS;
  let encodedAuth = Buffer.from(
    `${appClientId}:${appClientPass}`,
    "binary"
  ).toString("base64");
  //auth body data
  const requestBody = {
    username: process.env.REACT_APP_SECURITY_APPUSER_ID,
    password: process.env.REACT_APP_SECURITY_APPUSER_PASS,
    grant_type: CONSTANT.AUTH_GRANTTYPE,
  };

  await publicFetch
    .post(URL.APPLOGIN_REQUEST, qs.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
        Authorization: "Basic " + encodedAuth,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      console.log(error);
      throw new Error(properties.com_parval_label_applogin_error);
    });

  return responseData;
};

export const SVSessionValidateRequest = async (email, sessionCode) => {
  let responseData = {};
  const requestBody = {
    username: email,
    sessionCode: sessionCode,
  };
  await publicFetch
    .post(URL.SESSIONVALIDATE_REQUEST, JSON.stringify(requestBody), {
      headers: { ...CONSTANT.HEADER_TYPE2 },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 500)
      ) {
        throw new Error(properties.com_parval_label_login_sessioncode_error);
      }
      throw new Error(properties.com_parval_label_login_error);
    });

  return responseData;
};
