import React, { lazy } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
  Navigate,
} from "react-router-dom";
import { AuthLayout } from "../components/security/AuthLayout";
import { getAuthData } from "../utils/LocalStorageManager";
//import OperationForm from "../components/operation/OperationForm-old";
import OperationForm from "../components/operation/OperationForm";
import OperationFormApiParval from "../components/operation/OperationFormApiParval";
import DashboardHome from "../pages/DashboardHome";
import RecordParval from "../pages/operation/RecordParval";

/** layout */
const Layout = lazy(() => import("../components/template/Layout"));

/** Main page */
const Session = lazy(() => import("../pages/session/Session"));
const OperationHome = lazy(() => import("../pages/operation/OperationHome"));

/** Utils page */
const NotFound = lazy(() => import("../pages/utils/NotFound"));

export const appRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={async () => defer({ authPromise: getAuthData() })}
    >
      <Route exact path="/session" element={<Session />} />
      <Route path="/" element={<Layout />}>
        <Route index element={<OperationHome />} />
        <Route path="/record-parval" element={<RecordParval />} />

        <Route path="/createClient" element={<OperationForm />} />
        <Route path="/createClient/:wId" element={<OperationForm />} />
        <Route path="/client" element={<OperationFormApiParval />} />
        <Route path="/client/:wId" element={<OperationFormApiParval />} />
      </Route>
      <Route exact path="/notfound" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/notfound" replace />} />
    </Route>
  )
);
