import React from "react";
import "../../resources/styles/loadingStyle.css";

const Loading = () => (
  <>
    <div className="container">
      <div className="row">
        <div className="animationload">
          <div className="osahanloading"></div>
        </div>
      </div>
    </div>
  </>
);

export default Loading;
