import { URL } from "../utils/UrlConstant"
import { privateFetch } from "../utils/CustomFetch"
import { CONSTANT } from "../utils/Constant"


export const sendNotification = async (data) => {
  let responseData = {}  
  await privateFetch    
    .post(URL.NOTIFICATION_ACC(data.name, data.roles), {
      headers: {
        ...CONSTANT.HEADER_TYPE2,
      },
    }) 
    .then(async (response) => {
      responseData = await response.data
    })
    .catch(async (error) => {
      throw new Error(error.message)
    })
  return responseData
}