import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as logo } from "../../resources/images/logopv.svg";
import { ReactComponent as table } from "../../resources/icons/table.svg";
import { ReactComponent as list } from "../../resources/icons/list-check.svg";
import { ReactComponent as bracket } from "../../resources/icons/right-from-bracket.svg";
import { ReactComponent as house } from "../../resources/icons/house.svg";
import { ReactComponent as gear } from "../../resources/icons/gear.svg";
import { ReactComponent as trash } from "../../resources/icons/trash.svg";
import { ReactComponent as sorter } from "../../resources/icons/sorter.svg";
import { ReactComponent as pen } from "../../resources/icons/pen.svg";
import { ReactComponent as user } from "../../resources/icons/user.svg";
import { ReactComponent as facebook } from "../../resources/icons/facebook.svg";
import { ReactComponent as linkedin } from "../../resources/icons/linkedin.svg";
import { ReactComponent as instagram } from "../../resources/icons/instagram.svg";
import { ReactComponent as twitter } from "../../resources/icons/twitter.svg";
import { ReactComponent as angleRight } from "../../resources/icons/chevron-right.svg";
import { ReactComponent as CirclePlus } from "../../resources/icons/circle-plus.svg";
import { ReactComponent as Eye } from "../../resources/icons/eye.svg";
import { ReactComponent as File } from "../../resources/icons/file-arrow-down.svg";

export const Logo = () => {
  return (
    <SvgIcon
      component={logo}
      viewBox="0 0 360 360"
      sx={{ fontSize: "8rem", width: 150, mb: 4 }}
    />
  );
};

export const HouseIcon = () => {
  return (
    <SvgIcon
      component={house}
      viewBox="0 0 576 512"
      sx={{ fontSize: ".8125rem", width: 16 }}
    />
  );
};
export const GearIcon = () => {
  return (
    <SvgIcon
      component={gear}
      viewBox="0 0 512 512"
      sx={{ fontSize: ".8125rem", width: 16 }}
    />
  );
};
export const TableIcon = () => {
  return (
    <SvgIcon
      component={table}
      viewBox="0 0 512 512"
      sx={{ fontSize: ".8125rem", width: 16 }}
    />
  );
};

export const ListCheckIcon = () => {
  return (
    <SvgIcon
      component={list}
      viewBox="0 0 576 512"
      sx={{ fontSize: ".8125rem", width: 16 }}
    />
  );
};

export const FromBracketIcon = () => {
  return (
    <SvgIcon
      component={bracket}
      viewBox="0 0 512 512"
      sx={{ fontSize: ".8125rem", width: 16 }}
    />
  );
};

export const SortertIcon = () => {
  return (
    <SvgIcon
      component={sorter}
      viewBox="0 0 512 512"
      sx={{ fontSize: "1rem", width: 16 }}
    />
  );
};

export const TrashIcon = ({ ...props }) => {
  return (
    <SvgIcon
      component={trash}
      viewBox="0 0 448 512"
      sx={{ fontSize: "1rem", width: 16 }}
      {...props}
    />
  );
};

export const Pen = () => {
  return (
    <SvgIcon
      component={pen}
      viewBox="0 0 512 512"
      sx={{ fontSize: "1rem", width: 16 }}
    />
  );
};

export const UserIcon = ({ ...props }) => {
  return (
    <SvgIcon
      component={user}
      viewBox="0 0 448 512"
      sx={{ fontSize: "1rem", width: 16 }}
      {...props}
    />
  );
};
export const Facebook = ({ ...props }) => {
  return (
    <SvgIcon
      component={facebook}
      viewBox="0 0 512 512"
      sx={{ fontSize: "1.625rem", width: 26 }}
      {...props}
    />
  );
};
export const Instagram = ({ ...props }) => {
  return (
    <SvgIcon
      component={instagram}
      viewBox="0 0 448 512"
      sx={{ fontSize: "1.625rem", width: 26 }}
      {...props}
    />
  );
};
export const Linkedin = ({ ...props }) => {
  return (
    <SvgIcon
      component={linkedin}
      viewBox="0 0 448 512"
      sx={{ fontSize: "1.625rem", width: 26 }}
      {...props}
    />
  );
};
export const Twitter = ({ ...props }) => {
  return (
    <SvgIcon
      component={twitter}
      viewBox="0 0 512 512"
      sx={{ fontSize: "1.625rem", width: 26 }}
      {...props}
    />
  );
};
export const AngleRightIcon = ({ ...props }) => {
  return (
    <SvgIcon
      component={angleRight}
      viewBox="0 0 384 512"
      sx={{ fontSize: "1rem", width: 16 }}
      {...props}
    />
  );
};
export const CirclePlusIcon = ({ ...props }) => {
  return (
    <SvgIcon
      component={CirclePlus}
      viewBox="0 0 512 512"
      sx={{ fontSize: "1rem", width: 16 }}
      {...props}
    />
  );
};
export const EyeIcon = ({ ...props }) => {
  return (
    <SvgIcon
      component={Eye}
      viewBox="0 0 576 512"
      sx={{ fontSize: "1rem", width: 16 }}
      {...props}
    />
  );
};
export const FileIcon = ({ ...props }) => {
  return (
    <SvgIcon
      component={File}
      viewBox="0 0 384 512"
      sx={{ fontSize: "1rem", width: 16 }}
      {...props}
    />
  );
};
