import { URL } from "../../utils/UrlConstant";
import { privateFetch } from "../../utils/CustomFetch";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constant";
import { formatDate } from "../../utils/UtilsFunction";
import Urls from "../../url/Urls";

export const SVFindRequest = async (page, filter) => {
  let responseData = {};
  const params = new URLSearchParams([
    ["startDate", formatDate(filter.startDate)],
    ["endDate", formatDate(filter.endDate)],
    ["identification", filter.identification],
    ["clientCode", filter.clientCode],
    ["name", filter.name],
    [
      "operationType",
      filter.operationType !== null && filter.operationType.id !== undefined
        ? filter.operationType.id
        : "",
    ],
    ["currencyCode", filter.currencyCode === "0" ? "" : filter.currencyCode],
    ["status", filter.status === "0" ? "" : filter.status],
    ["personType", filter.personType],
    ["deleted", false],
  ]);
  await privateFetch
    .get(URL.REQUEST_RECORD_LIST(page, Urls.registration_x_page), { params })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      console.log(error);
    });
  //throw new Error(properties.com_parval_label_record_find_error)

  return responseData;
};

export const SVFindByIdRequest = async (id) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_RECORD(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_record_findid_error);
    });
  return responseData;
};

export const SVSaveRequest = async (data) => {
  let responseData = {};
  console.log(data);
  /* const requestBody = {
    ticket: data.ticket,
    clientCode: data.clientCode,
    clientId: data.clientId,
    clientName: data.clientName,
    clientEmail: data.clientEmail,
    assignedExecutive: data.assignedExecutive,
    agreementDate: formatDate(data.agreementDate),
    settlementDate: data.equalDate
      ? formatDate(data.agreementDate)
      : formatDate(data.settlementDate),
    executiveObservations: data.executiveObservations,
    monitoringObservations: data.monitoringObservations,
    operationType: data.operationType,
    currencyCode: data.currencyCode,
    status: data.status,
    createdBy: data.createdBy,
    personType: data.personType,
  } */
  await privateFetch
    .post(URL.REQUEST_RECORD(null), JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE2,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const SVUpdateRequest = async (data) => {
  let responseData = {};
  console.log(data);
  /* const requestBody = {
    id: data.id,
    ticket: data.ticket,
    clientCode: data.clientCode,
    clientId: data.clientId,
    clientName: data.clientName,
    clientEmail: data.clientEmail,
    assignedExecutive: data.assignedExecutive,
    agreementDate: formatDate(data.agreementDate),
    settlementDate: formatDate(data.settlementDate),    
    executiveObservations: data.executiveObservations,
    monitoringObservations: data.monitoringObservations,
    operationType: data.operationType,
    currencyCode: data.currencyCode,
    status: data.status,
    modifiedBy: data.modifiedBy,
    createdBy: data.createdBy,
    personType: data.personType,
  } */
  await privateFetch
    .put(URL.REQUEST_RECORD(data.id), JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE2,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error actualizando expediente");
    });
  return responseData;
};
export const SVUpdateRequestStatus = async (data) => {
  let responseData = {};
  console.log(data);
  const requestBody = {
    id: data.id,
    ticket: data.ticket,
    clientCode: data.clientCode,
    clientId: data.clientId,
    clientName: data.clientName,
    clientEmail: data.clientEmail,
    assignedExecutive: data.assignedExecutive,
    agreementDate: data.agreementDate,
    settlementDate: data.settlementDate,
    executiveObservations: data.executiveObservations,
    monitoringObservations: data.monitoringObservations,
    operationType: data.operationType,
    currencyCode: data.currencyCode,
    status: data.status,
    modifiedBy: data.modifiedBy,
    createdBy: data.createdBy,
    personType: data.personType,
  };
  await privateFetch
    .put(URL.REQUEST_RECORD(data.id), JSON.stringify(data), {
      headers: {
        ...CONSTANT.HEADER_TYPE2,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error actualizando expediente");
    });
  return responseData;
};

export const SVDeleteRequest = async (id, username) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_RECORD_DELETE(id, username), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_record_delete_error);
    });
  return responseData;
};

export const SaveNoteRequest = async (data) => {
  let responseData = {};
  const requestBody = {
    description: data.description,
    createdBy: data.createdBy,
  };

  await privateFetch
    .post(
      URL.SAVE_NOTES_BY_RECORD(data.expedientId),
      JSON.stringify(requestBody),
      {
        headers: {
          ...CONSTANT.HEADER_TYPE2,
        },
      }
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_record_save_error);
    });
  return responseData;
};

export const UpdateNoteRequest = async (data) => {
  let responseData = {};
  console.log(data);
  const requestBody = {
    description: data.description,
    createdBy: data.createdBy,
    toDo: data.toDo,
  };

  await privateFetch
    .put(
      URL.UPD_NOTES_BY_RECORD(data.expedientId, data.id),
      JSON.stringify(requestBody),
      {
        headers: {
          ...CONSTANT.HEADER_TYPE2,
        },
      }
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_record_save_error);
    });
  return responseData;
};

export const DeleteNoteRequest = async (id, user) => {
  let responseData = {};
  const params = new URLSearchParams([["user", user]]);
  console.log(id);
  console.log(user);
  await privateFetch
    .delete(URL.DELETE_NOTES_BY_RECORD(id), { params })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_record_save_error);
    });
  return responseData;
};

export const SVFindByIdAPIParval = async (filter) => {
  let responseData = {};
  await privateFetch
    .post(URL.REQUEST_RECORD_ID, JSON.stringify(filter), {
      headers: {
        ...CONSTANT.HEADER_TYPE2,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_record_findid_error);
    });
  return responseData;
};
