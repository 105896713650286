import { useEffect } from "react"
import Swal from "sweetalert2"

const MessageManager = ({ type, msg, details, callback }) => {
  useEffect(() => {
    showMessage()
  }, [])

  const showMessage = () => {
    Swal.fire({
      title: `<span style="font-weight: 400">${msg}</span>`,
      text: details,
      icon: type,
      confirmButtonText: "Ok",
      confirmButtonColor: "#f1b80e",
    }).then(() => callback && callback())
  }

  return <></>
}

export default MessageManager
