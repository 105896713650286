import React from "react";
import ReactDOM from "react-dom/client";
import {appRoutes} from "./routers/AppRoutes";
import "bootstrap/dist/css/bootstrap.css";
import "./resources/styles/parval.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./theme/theme";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router= {appRoutes} />
    </ThemeProvider>
);


reportWebVitals();
