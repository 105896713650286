import { URL } from "../utils/UrlConstant"
import { privateFetch } from "../utils/CustomFetch"
import { properties } from "../utils/Properties_es"
import { CONSTANT } from "../utils/Constant"
import { formatDate } from "../utils/UtilsFunction"

export const getAllRequest = async () => {
  let responseData = {}
  await privateFetch
    .get(URL.ALL_DOC, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_record_findid_error)
    })
  return responseData
}

export const getAllOperationType = async () => {
  let responseData = {}
  await privateFetch
    .get(URL.ALL_OPER_TYPE, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_record_findid_error)
    })
  return responseData
}

export const getDocumentByOperationTypeByExp = async (id, idOperation) => {
  let responseData = {}
  await privateFetch
    .get(URL.DOCUMENT_BY_OPERATION_BY_EXP(id, idOperation), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_record_findid_error)
    })
  return responseData
}

export const getNotesByRecord = async (id, page) => {
  let responseData = {}
  await privateFetch
    .get(URL.NOTES_BY_RECORD(id, page), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_record_findid_error)
    })
  return responseData
}

export const getDocumentByOperationType = async (id) => {
  let responseData = {}
  await privateFetch
    .get(URL.DOCUMENT_BY_OPERATION, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_record_findid_error)
    })
  return responseData
}

export const uploadDocumentRequest = async (data) => {
  let responseData = {}
  await privateFetch
    .post(URL.UPLOAD_DOC, data, {
      headers: {
        ...CONSTANT.HEADER_TYPE3,
      },
    })
    .then(async (response) => {
      responseData = await response.data
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message) 
    })
  return responseData
}

export const deleteDocumentRequest = async (id) => {
  let responseData = {}
  await privateFetch
    .delete(URL.DELETED_DOC(id), {})
    .then(async (response) => {
      responseData = await response.data
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_document_delete_error)
    })
  return responseData
}

export const getRequestDownloadDocumentClient = async (id, nameDocument) => {
  await privateFetch
    .get(URL.DOWNLOAD_DOC(id), { responseType: "blob" })
    .then(async (response) => {
      let responseData = await response.data
      const url = window.URL.createObjectURL(new Blob([responseData]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", nameDocument)
      document.body.appendChild(link)
      link.click()
    })
    .catch((error) => {
      throw new Error("Error en consulta de documentos por cliente")
    })
}

export const getRequestImageDocumentClient = async (id, nameDocument, ext) => {
  let responseData = {}
  let url
  const MSDocFTMaps = {
    doc: ["doc", "application/msword"],
    docx: [
      "docx",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
    xls: ["xls", "application/vnd.ms-excel"],
    xlsx: [
      "xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    ppt: ["ppt", "application/vnd.ms-powerpoint"],
    pptx: [
      "pptx",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ],
  }
  await privateFetch
    /* .get(
      URL.VIEW_DOWNLOAD_DOC(nameDocument), { responseType: "blob" }
    ) */
    .get(URL.DOWNLOAD_DOC(id), { responseType: "blob" })
    .then(async (response) => {
      responseData = await response.data
      url = window.URL.createObjectURL(new Blob([responseData]))
      //url = window.URL.createObjectURL(responseData);
      //let fileOfBlob = new File([url], nameDocument);
      //console.log(fileOfBlob)
    })
    .catch((error) => {
      throw new Error("Error en consulta de documentos por cliente")
    })
  return url
}
