import React, {
  createContext,
  useMemo,
  useState,
  useLayoutEffect,
  useEffect,
} from "react";
import { setAuthData } from "../utils/LocalStorageManager";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { getAllOperationType } from "../services/DocumentService";
import Urls from "../url/Urls";
//define context
const AppContext = createContext();
const { Provider } = AppContext;

const AppProvider = ({ children, authData }) => {
  const [authInfo, setAuthInfo] = useState(authData);
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [alertMsg, setAlertMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [modalData, setModalData] = useState({
    modalOpen: false,
    modalType: null,
    modalObject: null,
  });
  const [authenticate, setAuthenticate] = useState(authData != null);
  const [operationTypeData, setOperationTypeData] = useState(null);
  const [clientData, setClientData] = useState(null);

  //navigate
  const navigate = useNavigate();

  const loginProvider = async (loginData) => {
    try {
      //save login data in storage
      const authData = await setAuthData("authOPInfo", loginData);
      if (!authData) {
        setAuthenticate(false);
        return;
      }
      const decodeToken = jwt_decode(authData?.tokenInfo?.access_token);
      const expiresAt = decodeToken?.exp;
      let valid = new Date().getTime() / 1000 < expiresAt;
      if (valid) {
        setAuthenticate(valid);
        setAuthInfo(authData);
        navigate("/", { replace: true });
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setAuthenticate(false);
    // eslint-disable-next-line
  };

  const logoutProvider = async (e) => {
    //e.preventDefault();
    await setAuthData("authOPInfo", null);
    setAuthenticate(false);
    window.location.replace(Urls.base_login);
  };

  /*  useEffect(() => {
    let time = 300000;
    let timeOutTimer = setTimeout(logoutProvider, time);
    const checkInactivity = () => {
      window.onclick = () => checkInactivity();
      window.onkeypress = () => checkInactivity();
      window.addEventListener("mousemove", checkInactivity);
      clearTimeout(timeOutTimer);
      timeOutTimer = setTimeout(logoutProvider, time);
    };
    checkInactivity();
  }, []); */

  /**
   * resetErrorMsg
   */
  const resetErrorMsg = () => {
    setErrorMsg(null);
  };

  /**
   * resetSuccessMsg
   */
  const resetSuccessMsg = () => {
    setSuccessMsg(null);
  };

  /**
   * resetAlertMsg
   */
  const resetAlertMsg = () => {
    setAlertMsg(null);
  };

  const handleFetchOperationType = async () => {
    try {
      let operationAllData = await getAllOperationType();
      if (operationAllData) {
        setOperationTypeData(operationAllData.content);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useLayoutEffect(() => {
    const dataOperations = async () => handleFetchOperationType();
    dataOperations();
  }, []);

  const values = useMemo(
    () => ({
      authInfo,
      authenticate,
      isLoading,
      errorMsg,
      alertMsg,
      successMsg,
      modalData,
      operationTypeData,
      loginProvider,
      logoutProvider,
      setLoading,
      resetErrorMsg,
      setErrorMsg,
      resetSuccessMsg,
      setSuccessMsg,
      setModalData,
      setAlertMsg,
      resetAlertMsg,
      clientData,
      setClientData,
    }),
    // eslint-disable-next-line
    [
      authInfo,
      authenticate,
      isLoading,
      errorMsg,
      alertMsg,
      successMsg,
      modalData,
      operationTypeData,
      clientData,
    ]
  );

  return <Provider value={values}>{children}</Provider>;
};

export { AppContext, AppProvider };
