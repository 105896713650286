export const CONSTANT = {
  /** DISPATCHER PREFIX */
  DISPATCHER_SECURITY_PREFIX: "/security",
  DISPATCHER_USER_PREFIX: "/users",
  DISPATCHER_MAINTENANCE_PREFIX: "/maintenance",
  DISPATCHER_EXTERNAL_PREFIX: "/externalservices/external-service",
  //DISPATCHER_MAINTENANCE_PREFIX: "",

  /** TYPE MESSAGE */
  SUCCESS_MSG: 1,
  DANGER_MSG: 2,
  WARNING_MSG: 3,

  /** DEFAULT YES AND NOT */
  DEFAULT_YES: "S",
  DEFAULT_NOT: "N",

  /** IDENTIFICATION TYPE VALUE  */
  IDENTIFICATION_NATIONAL_VALUE: "N",
  IDENTIFICATION_FOREIGN_VALUE: "E",

  /** PHONETYPE */
  CODPHONE: 506,

  /** LANGUAGE */
  DEFAULT_LANGUAGE_ES: "ES",
  DEFAULT_LANGUAGE_EN: "EN",

  /** SOCIALNETWORKTYPE */
  SOCIALTYPE_FACEBOOK: "FACEBOOK",
  SOCIALTYPE_INSTAGRAM: "INSTAGRAM",
  SOCIALTYPE_TWITTER: "TWITTER",
  SOCIALTYPE_LINKEDIN: "LINKEDIN",
  SOCIALTYPE_YOUTUBE: "YOUTUBE",

  /** REQUEST STATUS INFO */
  STATUS_INFO_ACTIVE: "ACTIVE",
  STATUS_INFO_DELETE: "DELETED",
  STATUS_INFO_UPDATED: "UPDATED",

  DATE_FORMAT: "dd/MM/yyyy",

  /** HEADER TYPE */
  HEADER_TYPE: {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
  },
  HEADER_TYPE2: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },

  HEADER_TYPE3: {
    Accept: "application/json",
    "Content-Type":
      "multipart/form-data; boundary=----WebKitFormBoundaryAGajSuGLyB3CXIsT",
  },

  HEADER_TYPE_PARVAL: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "auth-service": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsIm5hbWUiOiJBZG1pbmlzdHJhZG9yIiwidXNlcm5hbWUiOiJhY2NvdW50IiwiZW5hYmxlIjp0cnVlLCJpYXQiOjE2ODE3NDIwMDJ9._Qdetde4eTuMsHgFK12uyQBQ6V9VwLVHBAhYhdqlYi0"
  },

  AUTH_GRANTTYPE: "password",
}
