import axios from "axios";
import { getItem } from "./LocalStorageManager";
import jwt_decode from "jwt-decode";
import Urls from "../url/Urls";

/**
 * Axion public
 */
const publicFetch = axios.create({
  baseURL: Urls.base_url + Urls.api_context,
});

/**
 * Axion private - internal parval endpoints
 */
const privateFetch = axios.create({
  baseURL: Urls.base_url + Urls.api_context,
});

/**
 * Axion private - interceptors
 */
privateFetch.interceptors.request.use(async (config) => {
  //validate uri to send token
  const { origin } = new URL(config.baseURL + config.url);
  const allowedOrigins = [Urls.base_url];
  const authInfo = await getItem("authOPInfo");
  const decodeToken = jwt_decode(authInfo?.tokenInfo?.access_token);
  const expiresAt = decodeToken.exp;
  let valid = new Date().getTime() / 1000 < expiresAt;
  if (allowedOrigins.includes(origin) && valid) {
    config.headers.Authorization = `Bearer ${authInfo?.tokenInfo?.access_token}`;
  }
  return config;
});

/**
 * Axion private external- external parval endpoints
 */
const privateExternalFetch = axios.create({
  baseURL:
    process.env.REACT_APP_PARVAL_EXTERNAL_API_BASE_URL +
    process.env.REACT_APP_PARVAL_EXTERNAL_API_CONTEXT,
});

/**
 * Axion private external- interceptors
 */
privateExternalFetch.interceptors.request.use(async (config) => {
  //validate uri to send token
  const { origin } = new URL(config.baseURL + config.url);
  const allowedOrigins = [process.env.REACT_APP_PARVAL_EXTERNAL_API_BASE_URL];
  if (allowedOrigins.includes(origin)) {
    config.headers.Authorization =
      process.env.REACT_APP_PARVAL_EXTERNAL_AUTHORIZATION;
  }
  return config;
});

export { publicFetch, privateFetch, privateExternalFetch };
