/*****************************************************************************
 * REVISION 1.0
 * Fecha: 13/01/2023
 *
 * @description JS para manejo de labels
 * @author lferrer
 * @version 1.0
 *
 ****************************************************************************/
export const properties = {
  /** General */

  /** footer */
  com_parval_label_footer_terms_conditions: "Términos y Condiciones",
  /** Page not found */
  com_parval_label_page_404_title: "Oops!",
  com_parval_label_page_404_subtitle: "Página no encontrada",

  /** Loading */
  com_parval_label_loading_title: "Cargando",

  /** Home */
  com_parval_label_home_title: "Parval",

  /** General button labels */
  com_parval_label_button_accept_title: "Aceptar",
  com_parval_label_button_ok_title: "Ok",
  com_parval_label_button_cancel_title: "Cancelar",
  com_parval_label_button_save_title: "Registrar",
  com_parval_label_button_save_title2: "Guardar",
  com_parval_label_button_update_title: "Editar",
  com_parval_label_button_detail_title: "Ver detalle",
  com_parval_label_button_changestate_title: "Cambiar estado",
  com_parval_label_button_delete_title: "Eliminar",
  com_parval_label_button_back_title: "Atras",
  com_parval_label_button_back2_title: "Volver",
  com_parval_label_button_add_title: "Agregar",
  com_parval_label_button_search_title: "Buscar",
  com_parval_label_button_clear_title: "Limpiar",
  com_parval_label_button_login_title: "Iniciar Sesión",
  com_parval_label_button_logout_title: "Cerrar sesión",
  com_parval_label_button_close_title: "Cerrar",
  com_parval_label_button_view_title: "Ver",
  com_parval_label_button_exit_title: "Salir",
  com_parval_label_button_forgotpass_title: "Recuperar contraseña",
  com_parval_label_button_updatepass_title: "Actualizar contraseña",
  /** General labels */
  com_parval_label_id: "ID",
  com_parval_label_dealticket: "Dealticket",
  com_parval_label_clientcode: "Cód. Cliente",
  com_parval_label_currency: "Moneda",
  com_parval_label_settlementdate: "Fecha de pacto",
  com_parval_label_agreementdate: "Fecha de liquidación",
  com_parval_label_operationtype: "Tipo de operación",
  com_parval_label_persontype: "Tipo de persona",
  com_parval_label_persontype_physical: "Físico",
  com_parval_label_persontype_legal: "Jurídico",
  com_parval_label_persontype_physical_placeholder: "Cédula",
  com_parval_label_persontype_legal_placeholder: "RNC",
  com_parval_label_identification: "Identificación",
  com_parval_label_name: "Nombre",
  com_parval_label_status: "Status",
  com_parval_label_table_action: "Acción",
  com_parval_label_table_empty: "No existen registros",
  /** General error labels in forms */
  com_parval_label_field_required: "Campo requerido",
  com_parval_label_field_format: "Formato invalido",
  com_parval_label_field_maxlenght: (lenght) =>
    `Longitud máxima permitida (${lenght})`,
  com_parval_label_field_minlenght: (lenght) =>
    `Longitud minima permitida (${lenght})`,
  /** General sucess labels in process */
  com_parval_label_request_save: "Registro insertado con exito",
  com_parval_label_request_update: "Registro actualizado con exito",
  com_parval_label_request_delete: "Registro eliminado con exito",
  /** General error labels in process */
  com_parval_label_request_connection: "Falla en solicitud al servidor",
  com_parval_label_request_unauthorized:
    "No tiene permiso para realizar esta acción",
  com_parval_label_request_badrequest:
    "Falla en parametros dentro de la solicitud al servidor",
  /** General modal label  */
  com_parval_label_modal_delete_title: "Eliminar Registro",

  /** APP LOGIN MSG*/
  com_parval_label_applogin_error:
    "Error en proceso de inicio de sesión de aplicación",
  com_parval_label_login_sessioncode_error:
    "Error en proceso de validar el código de sesión",
  com_parval_label_login_error: "Error en proceso de inicio de sesión",

  /** PARVAL CLIENT ACCOUNT MSG*/
  com_parval_label_clientaccount_error:
    "Error en proceso de obtener el listado de cuentas del cliente",
  com_parval_label_clientaccount_error2:
    "No existen cuentas asociadas al cliente, cliente no existe",

  /**Record label */
  com_parval_label_record_add_title: "Agregar expediente",
  com_parval_label_record_update_title: "Editar expediente",
  com_parval_label_record_state_title: "Cambiar estado del expediente",

  /** Record success*/
  com_parval_label_record_statusupdate_general_sucess:
    "El cambio de estado se completo",
  /** Record service error label */
  com_parval_label_record_find_error: "Error en consulta de expedientes",
  com_parval_label_record_findid_error:
    "Error en consulta por id de expediente",
  com_parval_label_record_save_error: "Error en proceso de guardar expediente",
  com_parval_label_record_update_error:
    "Error en proceso de actualizar expediente",
  com_parval_label_record_delete_error:
    "Error en proceso de eliminar expediente",

  /** Document service error label */
  com_parval_label_document_find_error: "Error en consulta de documento",
  com_parval_label_document_findid_error:
    "Error en consulta por id de documento",
  com_parval_label_document_save_error:
    "Error en proceso de guardar documento",
  com_parval_label_document_update_error:
    "Error en proceso de actualizar documento",
  com_parval_label_document_delete_error:
    "Error en proceso de eliminar documento", 
};
