import { useEffect, useLayoutEffect, useState } from "react";
import { useApp } from "../../hooks/useApp";
import {
  Box,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  FormControl,
  useTheme,
  TextField,
  Button,
  Stack,
  Divider,
  Select,
  InputLabel,
  MenuItem,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  FormGroup,
  Checkbox,
} from "@mui/material";
import InputLabelToForm from "../../components/utils/InputLabelToForm";
import DashboardHome from "../DashboardHome";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { addDays } from "date-fns";
import es from "date-fns/locale/es";
import { requestExternalApi } from "../../services/externalapi/ExternalApi";
import {
  formatDate,
  validateIdentificationPH,
} from "../../utils/UtilsFunction";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import { CONSTANT } from "../../utils/Constant";
import { properties } from "../../utils/Properties_es";
import { datos } from "../../resources/data";
import Loading from "../../components/utils/Loading";
import CustomPagination from "../../components/utils/CustomPagination";
import { format } from "date-fns";
import { SVSaveRequest } from "../../services/record/RecordService";
import { setItem, getItem, removeItem } from "../../utils/LocalStorageManager";

const RecordParval = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  registerLocale("es", es);
  const {
    authInfo,
    isLoading,
    setLoading,
    errorMsg,
    setErrorMsg,
    setSuccessMsg,
    modalData,
    setModalData,
    operationTypeData,
    clientData,
    setClientData,
  } = useApp();
  /* const years = range(
    new Date().setFullYear(new Date().getFullYear() - 3),
    new Date().getFullYear() + 1,
    1
  ); */
  const years = [];
  years.push(new Date().getFullYear());

  for (let i = 0; i < 5; i++) {
    years.push(new Date().getFullYear() - i);
  }

  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Setiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const filterObject = {
    //minDate: new Date(new Date().getFullYear(), 0, 2),
    minDate: new Date().setFullYear(new Date().getFullYear() - 2),
    maxDate: new Date(),
    startDate: new Date(),
    endDate: new Date(),
    rifId: "",
    //rifId: "D0040209090303",
    mesa: "",
    mercado: "",
    tpfecha: "",
    Ctacustodia: "",
    actpas: null,
  };
  const initActpas = {
    activo: false,
    pasivo: false,
  };
  const [filter, setFilter] = useState(filterObject);

  const [externalAPI, setExternalAPI] = useState(null);

  const [actpas, setActpas] = useState(initActpas);
  const [page, setPage] = useState(0);
  const [columnOrder, setColumnOrder] = useState("");
  const [btnSort, setBtnSort] = useState("asc");

  const toggleActpas = (e) => {
    setActpas({
      ...actpas,
      [e.target.name]: e.target.checked,
    });
  };

  useEffect(() => {
    setFilter({
      ...filter,
      actpas:
        actpas.activo && actpas.pasivo
          ? 99
          : actpas.activo && !actpas.pasivo
          ? 0
          : !actpas.activo && actpas.pasivo
          ? 1
          : null,
    });
  }, [actpas]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });

    if (name === "rifId") {
      setActpas(initActpas);
    }
  };

  const handleChangeDate = (value) => {
    //const { name, value } = e.target;
    const date = value;
    setFilter({
      ...filter,
      startDate: value,
      endDate: value,
    });
  };

  useEffect(() => {
    const dataInit = async () => {
      const localDataExternal = await getItem("externalAPIData");
      const filterLocalDataExternal = await getItem("filterExternalAPIData");
      if (localDataExternal) {
        console.log("entra");
        setFilter({
          ...filter,
          startDate: filterLocalDataExternal.startDate,
          endDate: filterLocalDataExternal.endDate,
          rifId: filterLocalDataExternal.rifId,
          mesa: filterLocalDataExternal.mesa,
          mercado: filterLocalDataExternal.mercado,
          tpfecha: filterLocalDataExternal.tpfecha,
          Ctacustodia: filterLocalDataExternal.Ctacustodia,
          actpas: filterLocalDataExternal.actpas,
        });
        setActpas({
          ...actpas,
          activo:
            filterLocalDataExternal.actpas === 99 ||
            filterLocalDataExternal.actpas === 0
              ? true
              : false,
          pasivo:
            filterLocalDataExternal.actpas === 99 ||
            filterLocalDataExternal.actpas === 1
              ? true
              : false,
        });
        setExternalAPI(localDataExternal);
      } /* else {
        await handleFetchExternalApi(filterObject, page, columnOrder, btnSort);
      } */
    };
    dataInit();
    // eslint-disable-next-line
  }, []);

  const handleFetchExternalApi = async (
    filter,
    currentPage,
    columnOrder,
    btnSort
  ) => {
    setLoading(true);
    await removeItem("filterExternalAPIData");
    await removeItem("externalAPIData");
    let externalAPIData;
    let newFilter = {};

    try {
      setPage(currentPage);
      if (filter.actpas !== null) {
        newFilter = {
          Desde: filter.startDate,
          Hasta: filter.endDate,
          Mesa: filter.mesa,
          Mercado: filter.mercado,
          tpfecha: filter.tpfecha,
          actpas: filter.actpas,
          Ctacustodia: "",
        };
        let externalAPIData1 = await requestExternalApi(
          newFilter,
          "transactxdia",
          currentPage,
          columnOrder,
          btnSort
        );
        //externalAPIData = externalAPIData1.CadJson?.Operacion;
        externalAPIData = externalAPIData1;
      } else if (filter.rifId !== "") {
        if (filter.mesa !== "") {
          newFilter = {
            Desde: filter.startDate,
            Hasta: filter.endDate,
            ID: filter.rifId,
            Mesa: filter.mesa,
            Ctacustodia: "",
          };
          let externalAPIData2 = await requestExternalApi(
            newFilter,
            "transact-conesko",
            currentPage,
            columnOrder,
            btnSort
          );
          //externalAPIData = externalAPIData2?.CadJson?.Operacion;
          externalAPIData = externalAPIData2;
        } else {
          newFilter = {
            Desde: formatDate(filter.startDate),
            Hasta: formatDate(filter.endDate),
            rif: filter.rifId,
          };
          let externalAPIData3 = await requestExternalApi(
            newFilter,
            "venc-mt",
            currentPage,
            columnOrder,
            btnSort
          );
          externalAPIData = {
            content: externalAPIData3.CadJson?.Operacion
              ? [externalAPIData3.CadJson?.Operacion]
              : null,
          };

          //externalAPIData = { content: [externalAPIData3.CadJson?.Operacion] };
          //console.log(externalAPIData);
        }
      } else {
        setExternalAPI(null);
      }
      if (externalAPIData) {
        setExternalAPI(externalAPIData);
        await setItem("filterExternalAPIData", filter);
        await setItem("externalAPIData", externalAPIData);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setExternalAPI(null);
      setErrorMsg(error.message);
    }
  };

  const handleApplyFilter = async (e) => {
    e.preventDefault();
    await handleFetchExternalApi(filter, page, columnOrder, btnSort);
  };

  const handleClearFilter = async (e) => {
    setFilter(filterObject);
    setActpas(initActpas);
    handleFetchExternalApi(filterObject);
  };

  const pagination = async (pageNumber) => {
    let customPage = pageNumber - 1;
    if (customPage !== page) {
      await handleFetchExternalApi(filter, customPage, columnOrder, btnSort);
    }
  };

  const sortTable = async (order, sort) => {
    setColumnOrder(order);
    setBtnSort(sort);
    await handleFetchExternalApi(filter, page, order, sort);
  };

  /*  const handleAdd = async (data) => {
    setLoading(true);
    console.log(data);
    const formattedAccounts = data.accounts.map((objeto) => ({
      ...objeto,
      dealDate: format(new Date(objeto.dealDate), "dd/MM/yyyy"),
      liquiDate: format(new Date(objeto.liquiDate), "dd/MM/yyyy"),
    }));
    console.log(formattedAccounts);
    try {
      let addData = await SVSaveRequest({
        ...data,
        accounts: formattedAccounts,
        createdBy: authInfo?.username,
        operationType: formattedAccounts[0].operationType,
      });

      if (!addData || !addData.id) {
        setLoading(false);
        return;
      }
      setLoading(false);
      setSuccessMsg(properties.com_parval_label_request_save);
      navigate(`/createClient/${addData.id}`);
      await handleFetchByID(addData.id);
      await handleFetctDocumentByOperation(
        addData.id,
        addData.accounts[0].operationType
      );
      await handleFetchNotes(addData.id, 0);
    } catch (error) {
      setErrorMsg(error.message);
      setLoading(false);
    }
  }; */

  const navigateClient = async (item) => {
    setClientData({
      ...item,
      startDate: filter.startDate,
    });

    navigate(`/client/${item.Nroorden}`);
  };

  return (
    <Box
      sx={{ px: 3.75, pt: 12, pb: 12, mr: "auto", ml: "auto", mb: 2 }}
      maxWidth="xl"
    >
      <DashboardHome />
      {isLoading && <Loading />}
      <Paper
        sx={{
          padding: theme.spacing(10),
          borderRadius: theme.spacing(4),
        }}
      >
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item xs={12}>
            <Typography
              sx={{ fontWeight: 700, mb: 2, fontSize: "1.06250rem" }}
              color="greyDue.dark"
            >
              Filtrar por:
            </Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <FormControl fullWidth sx={{ marginTop: "12px" }}>
              <InputLabelToForm>Fecha desde</InputLabelToForm>
              <DatePicker
                className="form-control"
                locale="es"
                name="startDate"
                minDate={filter.minDate}
                maxDate={new Date()}
                selected={filter.startDate ?? new Date()}
                dateFormat={CONSTANT.DATE_FORMAT}
                onChange={(date, e) => {
                  handleChangeDate(date);
                }}
                showYearDropdown
              />
            </FormControl>
          </Grid>
          {/* <Grid item md={3} sm={6} xs={12}>
            <FormControl fullWidth sx={{ marginTop: "12px" }}>
              <InputLabelToForm>Fecha hasta</InputLabelToForm>
              <DatePicker
                className="form-control"
                locale="es"
                minDate={filter.startDate}
                maxDate={addDays(filter.startDate, 1)}
                selected={filter.endDate ?? new Date()}
                dateFormat={CONSTANT.DATE_FORMAT}
                onChange={(date) => setFilter({ ...filter, endDate: date })}
                showDisabledMonthNavigation
              />
            </FormControl>
          </Grid> */}
          <Grid item md={3} sm={6} xs={12}>
            <FormControl fullWidth variant="standard">
              <InputLabel>Mercados</InputLabel>
              <Select
                name="mercado"
                value={filter.mercado}
                onChange={handleFilterChange}
              >
                <MenuItem value="">Seleccione</MenuItem>
                <MenuItem value="1">Renta fija</MenuItem>
                <MenuItem value="2">Renta variable</MenuItem>
                <MenuItem value="5">Mutuo</MenuItem>
                <MenuItem value="9">Todos</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={1} sm={6} xs={12}>
            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actpas.activo}
                      onChange={toggleActpas}
                      name="activo"
                    />
                  }
                  label="Activo"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actpas.pasivo}
                      onChange={toggleActpas}
                      name="pasivo"
                    />
                  }
                  label="Pasivo"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <FormControl fullWidth>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                sx={{ color: "#5d5e60" }}
              >
                Tipo de fecha
              </FormLabel>
              <RadioGroup
                row
                name="tpfecha"
                value={filter.tpfecha}
                onChange={handleFilterChange}
              >
                <FormControlLabel
                  value="C"
                  control={<Radio />}
                  label="Cierre"
                />
                <FormControlLabel value="V" control={<Radio />} label="Valor" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              label="Cntp/Cli"
              type="text"
              variant="standard"
              fullWidth
              name="rifId"
              value={filter.rifId}
              onChange={handleFilterChange}
              maxLength="100"
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <FormControl fullWidth variant="standard">
              <InputLabel>Mesa</InputLabel>
              <Select
                name="mesa"
                value={filter.mesa}
                onChange={handleFilterChange}
              >
                <MenuItem value="">Seleccione</MenuItem>
                <MenuItem value="1">Mesa 1</MenuItem>
                <MenuItem value="2">Mesa 2</MenuItem>
                <MenuItem value="99">Todas</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end" spacing={2.5}>
              <Button
                variant="contained"
                onClick={handleApplyFilter}
                color="primary"
              >
                Buscar
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClearFilter}
              >
                Limpiar
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Divider />
        <Stack sx={{ width: "100%", overflowX: "scroll", maxWidth: "100%" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  onClick={() =>
                    sortTable("nro_orden", btnSort === "desc" ? "asc" : "desc")
                  }
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  Nro. Orden
                  {btnSort === "desc" ? (
                    <KeyboardArrowDown />
                  ) : (
                    <KeyboardArrowUp />
                  )}
                </TableCell>
                <TableCell
                  align="right"
                  onClick={() =>
                    sortTable(
                      "deal_ticket",
                      btnSort === "desc" ? "asc" : "desc"
                    )
                  }
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  Dealticket
                  {btnSort === "desc" ? (
                    <KeyboardArrowDown />
                  ) : (
                    <KeyboardArrowUp />
                  )}
                </TableCell>
                <TableCell align="right">Cód. Cliente</TableCell>
                <TableCell align="right">Identificación</TableCell>
                <TableCell align="right">Nombre</TableCell>
                <TableCell align="right">Tipo de operación</TableCell>
                <TableCell align="right">Moneda</TableCell>
                <TableCell
                  align="right"
                  onClick={() =>
                    sortTable("fecha", btnSort === "desc" ? "asc" : "desc")
                  }
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  Fecha de pacto
                  {btnSort === "desc" ? (
                    <KeyboardArrowDown />
                  ) : (
                    <KeyboardArrowUp />
                  )}
                </TableCell>
                <TableCell align="right">Fecha de liquidación</TableCell>
                <TableCell align="right">Tipo de producto</TableCell>
                <TableCell align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {externalAPI !== null &&
                externalAPI?.content?.map((r, i) => (
                  <TableRow
                    key={i}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell>{r?.Nroorden}</TableCell>
                    <TableCell>{r?.Secuencia}</TableCell>
                    <TableCell align="right">{r?.Codigocliente}</TableCell>
                    <TableCell align="right">{r?.Cedulacliente}</TableCell>
                    <TableCell align="right" sx={{ maxWidth: "300px" }}>
                      {r?.Nombrecliente}
                    </TableCell>
                    <TableCell align="right">{r?.Tipocv}</TableCell>
                    <TableCell align="right">{r?.Moneda_abrevia}</TableCell>
                    <TableCell align="right">{r?.Fechapacto}</TableCell>
                    <TableCell align="right">{r?.Fechavenctitulo}</TableCell>
                    <TableCell align="right">{r?.Prd}</TableCell>
                    <TableCell>
                      <OverlayTrigger
                        key={"top-tooltip-update" + r?.id}
                        placement="top"
                        overlay={
                          <Tooltip id={"top-tooltip-update" + r?.id}>
                            Agregar
                          </Tooltip>
                        }
                      >
                        <button
                          variant="secondary"
                          /*  onClick={() => navigate(`/client/D00${r?.Cedulacliente}`)}  */
                          onClick={() => navigateClient(r)}
                          style={{
                            border: 0,
                            background: "transparent",
                            fontSize: "1.5rem",
                          }}
                        >
                          <i className="fa-solid fa-floppy-disk m-2 ico-blue" />
                        </button>
                      </OverlayTrigger>
                    </TableCell>
                  </TableRow>
                ))}

              {(externalAPI?.content === null ||
                externalAPI?.content.length <= 0) && (
                <TableRow>
                  <TableCell colSpan="11">No existen registros</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Stack>
        {externalAPI &&
          externalAPI.content &&
          externalAPI.content.length > 0 && (
            <div className="card-footer">
              <div className="row">
                <div className="col-12">
                  <CustomPagination
                    activePage={page}
                    itemsCountPerPage={externalAPI.size}
                    totalItemsCount={externalAPI.total_elements}
                    pageRangeDisplayed={5}
                    innerClass="pagination justify-content-end"
                    itemClass="page-item"
                    linkClass="page-link"
                    callback={pagination}
                  />
                </div>
              </div>
            </div>
          )}
      </Paper>
    </Box>
  );
};

export default RecordParval;
