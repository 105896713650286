import React, { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApp } from "../../hooks/useApp";
import {
  Grid,
  Typography,
  useTheme,
  TextField,
  Button,
  Stack,
  Divider,
  Box,
  Paper,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  IconButton,
  FormHelperText,
} from "@mui/material";
import InputLabelToForm from "../utils/InputLabelToForm";
import FancyTooltip from "../utils/FancyTooltip";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import DatePicker from "react-datepicker";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { format } from "date-fns";
import { SVFindByIdAPIParval } from "../../services/record/RecordService";
import { AngleRightIcon } from "../svgicons/SvgIcons";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constant";
import { parseDate } from "../../utils/UtilsFunction";
import { SVSaveRequest } from "../../services/record/RecordService";
import { requestExternalApiCustomer } from "../../services/externalapi/ExternalApi";
import SearchCustomerExternalApi from "../utils/SearchCustomerExternalApi";
import Loading from "../utils/Loading";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const OperationFormApiParval = () => {
  const { wId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  registerLocale("es", es);
  const {
    authInfo,
    setLoading,
    isLoading,
    setErrorMsg,
    setSuccessMsg,
    modalData,
    setModalData,
    operationTypeData,
    clientData,
  } = useApp();
  const [externalAPIData, setExternalAPI] = useState(null);
  const [externalAPIDataState, setExternalAPIState] = useState(null);
  const initData = {
    id: 0,
    status: "INCOMPLETE",
    createdDate: new Date(),
    modifiedDate: new Date(),
    modifiedBy: authInfo.username,
    accounts: null,
  };
  const [newExtAPIData, setNewExtAPIData] = useState(initData);
  const [filterOperation, setFilterOperation] = useState(null);

  const accountSchema = {
    dealTicket: Yup.string().required("Campo es obligatorio"),
    dealDate: Yup.date().required("Campo es obligatorio"),
    liquiDate: Yup.date().required("Campo es obligatorio"),
    operationType: Yup.string().required("Campo es obligatorio"),
    currency: Yup.string().required("Campo es obligatorio"),
  };

  const validation = Yup.object().shape({
    id: Yup.string(),
    status: Yup.string(),
    clientCode: Yup.string().required("Campo es obligatorio"),
    clientId: Yup.string().required("Campo es obligatorio"),
    clientName: Yup.string().required("Campo es obligatorio"),
    personType: Yup.string().required("Campo es obligatorio"),
    //operationType: Yup.number().required("Campo es obligatorio"),
    accounts: Yup.array().of(Yup.object().shape(accountSchema)),
  });

  const initApiData = {
    //startDate: clientData?.startDate && formatDate(clientData.startDate),
    /* endDate:
      clientData?.startDate &&
      formatDate(
        new Date(
          clientData?.startDate.setDate(clientData?.startDate.getDate() + 30)
        )
      ), */
    id:
      clientData?.Cedulacliente !== ""
        ? "D00" + clientData?.Cedulacliente.replace(/\s_|_|#|-|@|<>/g, "")
        : "",
  };

  useLayoutEffect(() => {
    const dataInit = async () => {
      await handleFetchExternalApi(initApiData);
    };

    dataInit();
    //console.log("clienDataInterna", clientData);
    // eslint-disable-next-line
  }, []);

  const handleFetchExternalApi = async (filter) => {
    setLoading(true);
    try {
      let extAPIData = await SVFindByIdAPIParval(filter);
      if (extAPIData) {
        setExternalAPI(extAPIData.CadJson.Operacion);
        setLoading(false);
      }
      setExternalAPIState(clientData);
      setLoading(false);
    } catch (error) {
      setExternalAPIState(clientData);
      setLoading(false);
      //setErrorMsg(error.message);
    }
  };

  useEffect(() => {
    const miArray = [];

    if (externalAPIDataState === null) {
      if (externalAPIData !== null) {
        externalAPIData.forEach(function (item) {
          let newAccount = {
            dealTicket: item.Secuencia,
            dealDate: item.Fechapacto
              ? new Date(parseDate(item.Fechapacto))
              : new Date(),
            liquiDate: item.Finimt
              ? new Date(parseDate(item.Finimt))
              : new Date(),
            operationType:
              item.Prd === "RV"
                ? "8"
                : item.Prd === "RF"
                ? "7"
                : item.Prd === "MT"
                ? "10"
                : item.Prd === "CT"
                ? "17"
                : item.Prd === "CV"
                ? "8"
                : "",
            currency: item.Moneda_abrevia,
            expedient: 0,
          };
          miArray.push(newAccount);
        });
        console.log(externalAPIData[0]);
        setNewExtAPIData({
          ...newExtAPIData,
          clientCode: externalAPIData[0].Codigocliente,
          clienName: externalAPIData[0].Nombrecliente.trim(),
          clientId: externalAPIData[0].Cedulacliente,
          personType: externalAPIData[0].Tppersonacliente === "0" ? "F" : "J",
          accounts: miArray,
        });
      }
    } else {
      //console.log(externalAPIDataState.Prd);
      let newAccountState =
        externalAPIDataState?.accounts &&
        externalAPIDataState?.accounts.map((exts) => ({
          dealTicket: exts?.dealTicket,
          dealDate: exts?.dealDate
            ? new Date(parseDate(exts?.dealDate))
            : new Date(),
          liquiDate: exts?.liquiDate
            ? new Date(parseDate(exts?.liquiDate))
            : new Date(),
          operationType:
            exts?.operationType === "RV"
              ? "8"
              : exts?.operationType === "RF"
              ? "7"
              : exts?.operationType === "MT"
              ? "10"
              : exts?.operationType === "CT"
              ? "17"
              : exts?.operationType === "CV"
              ? "8"
              : "",
          currency: exts?.currency,
          expedient: 0,
        }));
      setNewExtAPIData({
        ...newExtAPIData,
        clientCode: externalAPIDataState.Codigocliente ?? "",
        clienName:
          externalAPIDataState.Nombrecliente.trim() !== ""
            ? externalAPIDataState.Nombrecliente.trim()
            : "",
        clientId: externalAPIDataState.Cedulacliente ?? "",
        personType:
          externalAPIDataState.Tppersonacliente.replaceAll("-", "") === "0"
            ? "F"
            : "J",
        //accounts: [newAccountState],
        accounts: newAccountState,
      });
    }
    //reset(defaultValues);
  }, [externalAPIData, externalAPIDataState]);

  const defaultValues = {
    id: 0,
    status: "INCOMPLETE",
    createdDate: new Date(),
    modifiedDate: new Date(),
    modifiedBy: authInfo.username,
    clientCode: newExtAPIData.clientCode ? newExtAPIData.clientCode : "",
    clientId: newExtAPIData.clientId ? newExtAPIData.clientId : "",
    clientName: newExtAPIData.clienName ? newExtAPIData.clienName : "",
    personType: newExtAPIData.personType ? newExtAPIData.personType : "",
    operationType: newExtAPIData.operationType
      ? newExtAPIData.operationType
      : "",
    accounts: (newExtAPIData?.accounts && newExtAPIData.accounts) ?? [
      {
        dealTicket: "",
        dealDate: null,
        liquiDate: null,
        operationType: "",
        currency: "",
        expedient: 0,
      },
    ],
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
    control,
    reset,
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  const {
    fields: addAccountsField,
    append: addAccountsAppend,
    remove: addAccountsRemove,
  } = useFieldArray({
    control,
    name: "accounts",
    rules: {
      minLength: 1,
    },
  });

  useEffect(() => {
    reset(defaultValues);
  }, [externalAPIDataState, newExtAPIData]);

  const handleAdd = async (data) => {
    setLoading(true);
    console.log(data);
    const formattedAccounts = data.accounts.map((objeto) => ({
      ...objeto,
      dealDate: format(new Date(objeto.dealDate), "dd/MM/yyyy"),
      liquiDate: format(new Date(objeto.liquiDate), "dd/MM/yyyy"),
    }));
    console.log(formattedAccounts);
    try {
      let addData = await SVSaveRequest({
        ...data,
        accounts: formattedAccounts,
        createdBy: authInfo?.username,
        operationType: formattedAccounts[0].operationType,
      });

      if (!addData || !addData.id) {
        setLoading(false);
        return;
      }
      setLoading(false);
      setSuccessMsg(properties.com_parval_label_request_save);
      navigate(`/createClient/${addData.id}`);
    } catch (error) {
      setErrorMsg(error.message);
      console.log(error);
      setLoading(false);
    }
  };

  const handleOpenModal = async (event) => {
    event.preventDefault();
    const modalAction = event.currentTarget.name || event.currentTarget.id;
    let object = null;

    /* if (modalAction === "view") {
      object = { id: id, nameD: nameD, ext: extensionD };
    }
    if (modalAction === "deleteObservation") {
      object = { id: id };
    } */

    //open modal
    setModalData({
      ...modalData,
      modalOpen: true,
      modalType: modalAction,
      modalObject: object,
    });
  };

  const handleCancelModal = () => {
    setModalData({
      ...modalData,
      modalOpen: false,
      modalType: null,
      modalObject: null,
    });
  };

  const handleSearchCustomer = async (data) => {
    setLoading(true);
    try {
      const response = await requestExternalApiCustomer(data.id, data.cual);
      if (response) {
        if (response.Nmfull === "") {
          setErrorMsg(
            `No existen datos que coincidan con el código <strong>${data.id}</strong>`
          );
        } else {
          handleCancelModal();
          setValue("clientName", response.Nmfull);
          setValue("clientId", response.Rif.slice(3, response.Rif.length));
          setValue("clientCode", response.Id);
        }
      }
      setLoading(false);
    } catch (error) {
      handleCancelModal();
      setLoading(false);
      setErrorMsg(error.message);
    }
  };

  const onSubmit = async (data, e) => {
    const typeAction = e.target.name;
    switch (typeAction) {
      case "add":
        await handleAdd(data);
        break;
      case "customerSearch":
        await handleSearchCustomer(data);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <Box
        sx={{ px: 3.75, pt: 8, pb: 0, mr: "auto", ml: "auto", mb: 12 }}
        maxWidth="lg"
      >
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={() => navigate("/record-parval")}
          sx={{
            mb: 4,
            "&:hover": {
              color: `${theme.palette.secondary.dark}!important`,
            },
          }}
        >
          <AngleRightIcon
            sx={{
              marginRight: "0px",
              color: "inherit",
              transform: "translate(-4px, 0px) rotate(178deg)",
              fontSize: "1.25rem",
            }}
          />
          Regresar a operaciones pactadas
        </Button>
        <Paper
          sx={{
            padding: {
              xs: `${theme.spacing(10)} ${theme.spacing(5)}`,
              md: theme.spacing(10),
            },
            pb: theme.spacing(2),
            borderRadius: theme.spacing(4),
          }}
        >
          <form>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Typography variant="h3" color="greyDue.dark" sx={{ mb: 4 }}>
                Agregar expediente
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Typography
                variant="h5"
                color="greyDue.dark"
                sx={{ fontWeight: 400, fontSize: "1.25rem" }}
              >
                <AngleRightIcon
                  sx={{
                    marginRight: "0px",
                    color: "primary.main",
                    transform: "translateY(-3px)",
                    fontSize: "1.25rem",
                  }}
                />{" "}
                Datos del cliente
              </Typography>
              {defaultValues.clientCode === "" && (
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  name="customerSearch"
                  onClick={handleOpenModal}
                >
                  Buscar datos
                </Button>
              )}
            </Stack>
            <Divider sx={{ marginBottom: "50px" }} />
            <Grid container spacing={6} maxWidth="lg" sx={{ pb: 8 }}>
              <Grid item md={3} sm={6} xs={12}>
                <Controller
                  name={"clientCode"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Código del cliente"
                      type="text"
                      variant="standard"
                      fullWidth
                      value={value}
                      {...register("clientCode")}
                      disabled
                      error={errors?.clientId && true}
                      helperText={errors?.clientId?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Controller
                  name={"clientId"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Identificación"
                      type="text"
                      variant="standard"
                      fullWidth
                      value={value}
                      {...register("clientId")}
                      disabled
                      error={errors?.clientId && true}
                      helperText={errors?.clientId?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Controller
                  name={"clientName"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Nombre"
                      type="text"
                      variant="standard"
                      fullWidth
                      value={value}
                      disabled
                      {...register("clientName")}
                      error={errors?.clientName && true}
                      helperText={errors?.clientName?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Controller
                  name={"personType"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      variant="standard"
                      sx={{ pointerEvents: `${value !== "" && "none"}` }}
                      className={`${
                        defaultValues.personType !== "" ? "Mui-disabled" : ""
                      }`}
                      error={errors?.personType ?? false}
                    >
                      <InputLabel>Tipo de persona</InputLabel>
                      <Select
                        id="personType"
                        value={value}
                        {...register("personType")}
                        data-value={value}
                      >
                        <MenuItem value="">Seleccione</MenuItem>
                        <MenuItem value="F">
                          {properties.com_parval_label_persontype_physical}
                        </MenuItem>
                        <MenuItem value="J">
                          {properties.com_parval_label_persontype_legal}
                        </MenuItem>
                      </Select>
                      <FormHelperText error={true}>
                        {errors?.personType?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
            <Divider sx={{ mt: "10px", mb: "30px" }} />
            {addAccountsField.map((field, index) => {
              return (
                <div key={index}>
                  <Grid container spacing={6} maxWidth="lg" sx={{ pb: 8 }}>
                    <Grid item md={3} sm={6} xs={12}>
                      <Controller
                        name={`accounts.${index}.operationType`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <FormControl
                            fullWidth
                            variant="standard"
                            //className={`${value !== "" ? "Mui-disabled" : ""}`}
                            className={`${
                              defaultValues.accounts[index].operationType !== ""
                                ? "Mui-disabled"
                                : ""
                            }`}
                            error={
                              errors?.accounts?.[index]?.operationType ?? false
                            }
                          >
                            <InputLabel>Tipo de operación</InputLabel>
                            <Select
                              value={value}
                              label="Tipo de operación"
                              {...register(`accounts.${index}.operationType`)}
                            >
                              <MenuItem value="">Seleccione</MenuItem>
                              {operationTypeData &&
                                operationTypeData.map((op, i) => (
                                  <MenuItem key={i} value={op.id}>
                                    {op.name}
                                  </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText error={true}>
                              {
                                errors?.accounts?.[index]?.operationType
                                  ?.message
                              }
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                      <Controller
                        name={`accounts.${index}.dealTicket`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            label="Ticket"
                            type="text"
                            variant="standard"
                            fullWidth
                            value={value}
                            //className={`${value !== "" ? "Mui-disabled" : ""}`}
                            className={`${
                              defaultValues.accounts[index].dealTicket !== ""
                                ? "Mui-disabled"
                                : ""
                            }`}
                            {...register(`accounts.${index}.dealTicket`)}
                            error={
                              errors?.accounts?.[index]?.dealTicket && true
                            }
                            helperText={
                              errors?.accounts?.[index]?.dealTicket?.message
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                      <Controller
                        name={`accounts.${index}.currency`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <FormControl
                            fullWidth
                            variant="standard"
                            //className={`${value !== "" ? "Mui-disabled" : ""}`}
                            className={`${
                              defaultValues.accounts[index].currency !== ""
                                ? "Mui-disabled"
                                : ""
                            }`}
                            error={errors?.accounts?.[index]?.currency ?? false}
                          >
                            <InputLabel>Moneda</InputLabel>
                            <Select
                              labelId="currency"
                              id="currency"
                              value={value}
                              label="Moneda"
                              {...register(`accounts.${index}.currency`)}
                            >
                              <MenuItem value="">Seleccione</MenuItem>
                              <MenuItem value="USD">USD</MenuItem>
                              <MenuItem value="DOP">DOP</MenuItem>
                            </Select>
                            <FormHelperText error={true}>
                              {errors?.accounts?.[index]?.currency?.message}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                      <Controller
                        name={`accounts.${index}.dealDate`}
                        control={control}
                        render={({ field, ...props }) => (
                          <FormControl
                            fullWidth
                            sx={{ marginTop: "12px" }}
                            /* className={`${
                              field.value !== "" && field.value !== null
                                ? "Mui-disabled"
                                : ""
                            }`} */
                            className={`${
                              defaultValues.accounts[index].dealDate !== "" &&
                              defaultValues.accounts[index].dealDate !== null
                                ? "Mui-disabled"
                                : ""
                            }`}
                            error={errors?.accounts?.[index]?.dealDate ?? false}
                          >
                            <InputLabelToForm>Fecha de pacto</InputLabelToForm>
                            <DatePicker
                              locale="es"
                              className={`form-control ${field.value}`}
                              selected={field.value}
                              onChange={(date) => {
                                field.onChange(date);
                              }}
                              dateFormat={CONSTANT.DATE_FORMAT}
                            />
                            <FormHelperText error={true}>
                              {errors?.accounts?.[index]?.dealDate?.message}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12} sx={{ display: "flex" }}>
                      <Controller
                        name={`accounts.${index}.liquiDate`}
                        control={control}
                        render={({ field, ...props }) => (
                          <FormControl
                            fullWidth
                            sx={{ marginTop: "12px" }}
                            /* className={`${
                              field.value !== "" ? "Mui-disabled" : ""
                            }`} */
                            className={`${
                              defaultValues.accounts[index].liquiDate !== "" &&
                              defaultValues.accounts[index].liquiDate !== null
                                ? "Mui-disabled"
                                : ""
                            }`}
                            error={
                              errors?.accounts?.[index]?.liquiDate ?? false
                            }
                          >
                            <InputLabelToForm>
                              Fecha de liquidación
                            </InputLabelToForm>
                            <DatePicker
                              locale="es"
                              className={`form-control ${field.value}`}
                              selected={field.value}
                              onChange={(date) => {
                                field.onChange(date);
                              }}
                              dateFormat={CONSTANT.DATE_FORMAT}
                            />
                            <FormHelperText error={true}>
                              {errors?.accounts?.[index]?.liquiDate?.message}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                      {index > 0 && index === addAccountsField.length - 1 && (
                        <FancyTooltip title="Remover" placement="top">
                          <IconButton
                            aria-label="delete"
                            color="primary"
                            onClick={() => {
                              addAccountsRemove(index);
                            }}
                          >
                            <RemoveCircleIcon sx={{ fontSize: "1.563rem" }} />
                          </IconButton>
                        </FancyTooltip>
                      )}
                    </Grid>
                  </Grid>
                  <Divider sx={{ mt: "10px", mb: "30px" }} />
                </div>
              );
            })}
            <Stack
              direction="row"
              justifyContent="center"
              spacing={3}
              sx={{ mt: 4 }}
            >
              <Button
                variant="contained"
                color="primary"
                type="button"
                name="add"
                onClick={handleSubmit(onSubmit)}
              >
                Guardar
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="button"
                name="update"
                disabled
                onClick={handleSubmit(onSubmit)}
              >
                Guardar y enviar
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => navigate("/")}
              >
                Cancelar
              </Button>
            </Stack>
          </form>
        </Paper>
      </Box>
      {modalData?.modalType === "customerSearch" && (
        <SearchCustomerExternalApi
          isOpen={modalData?.modalOpen}
          handleCancelModal={handleCancelModal}
          handleSearchCustomer={handleSearchCustomer}
        />
      )}
    </>
  );
};

export default OperationFormApiParval;
