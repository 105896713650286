import { useEffect, useLayoutEffect, useState } from "react";
import {
  Box,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Autocomplete,
  FormControl,
  useTheme,
  TextField,
  Button,
  Stack,
  Divider,
  Select,
  InputLabel,
  MenuItem,
  Tabs,
  Tab,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";

function ariaProps(person) {
  return {
    id: `tab-${person}`,
    "aria-controls": `tabpanel-${person}`,
  };
}

const DashboardHome = () => {
  let url = useLocation();
  const [valueTab, setValueTab] = useState(1);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  let location = url.pathname.replace("/", "");

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
      <Tabs
        value={location !== "record-parval" ? 0 : valueTab}
        onChange={handleChangeTab}
        aria-label="records tabs"
      >
        <Tab
          label="Operaciones"
          component={Link}
          to={"/"}
          {...ariaProps("record")}
        />
        <Tab
          label="Operaciones pactadas"
          component={Link}
          to={"/record-parval"}
          {...ariaProps("record-parval")}
        />
      </Tabs>
    </Box>
  );
};

export default DashboardHome;
