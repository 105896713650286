import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import PdfView from "./pdf/PdfView";
import { getRequestImageDocumentClient } from "../../services/DocumentService";
import { useApp } from "../../hooks/useApp";
import { IconButton } from "@mui/material";
import Urls from "../../url/Urls";

const FileViewModal = ({ document, handleCancelModal, isOpen, modalSize }) => {
  const { setErrorMsg } = useApp();
  const [image, setImage] = useState(null);

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchImage(document.id, document.nameD, document.ext);
    };
    dataInit();
    // eslint-disable-next-line
  }, []);

  const handleFetchImage = async (id, name, ext) => {
    try {
      let documentByClient = await getRequestImageDocumentClient(id, name, ext);
      if (documentByClient) {
        setImage(documentByClient);
      }
    } catch (error) {
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  return (
    <>
      <Modal
        show={isOpen}
        keyboard={false}
        onHide={() => false}
        size="xl"
        centered
      >
        <Modal.Header style={{ background: "transparent", border: "0" }}>
          <IconButton
            aria-label="close"
            onClick={handleCancelModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center", padding: "32px" }}>
          {document.ext === "docx" ||
          document.ext === "doc" ||
          document.ext === "xlsx" ? (
            <>
              <iframe
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${Urls.base_url}/v1/maintenance/documents/storage/download/${document.id}/?originalName=${document.nameD}`}
                width="100%"
                height="800px"
                title={document.nameD}
              ></iframe>
            </>
          ) : document.ext === "pdf" ? (
            <PdfView
              pdf={`${Urls.base_url}/v1/maintenance/documents/storage/download/${document.id}/`}
            />
          ) : document.ext === "jpg" ||
            document.ext === "jpeg" ||
            document.ext === "png" ? (
            image && (
              <img
                src={image}
                style={{
                  width: "100%",
                  maxWidth: "600px",
                  margin: "0 auto",
                }}
                alt={document.nameD}
              />
            )
          ) : (
            <p>Archivo no soportado por el visor</p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FileViewModal;
