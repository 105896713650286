import { URL } from "../../utils/UrlConstant";
import { privateFetch } from "../../utils/CustomFetch";
import { privateExternalFetch } from "../../utils/CustomFetch";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constant";

export const requestExternalApi = async (
  data,
  endpointName,
  page,
  columnOrder,
  sort
) => {
  let newdata = {};
  newdata = {
    Json: "True",
    Param: [data][0],
  };
  let responseData = {};
  await privateFetch
    .post(
      URL.REQUEST_TRANSACTCONESKO(endpointName, page, 10, columnOrder, sort),
      JSON.stringify(newdata),
      {
        headers: {
          ...CONSTANT.HEADER_TYPE2,
        },
      }
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const requestExternalApiCustomer = async (id, cual) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_CUSTOMER_RIF(id, cual), {
      headers: {
        ...CONSTANT.HEADER_TYPE2,
      },
    })
    .then(async (response) => {
      responseData = await response.data.CadJson;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};
