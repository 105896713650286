import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useApp } from "../../hooks/useApp";
import { OverlayTrigger, Form } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import MessageManager from "../utils/MessageManager";
import {
  uploadDocumentRequest,
  getRequestDownloadDocumentClient,
} from "../../services/DocumentService";
import { CONSTANT } from "../../utils/Constant";
import { properties } from "../../utils/Properties_es";
import ResourceAccess from "../security/ResourceAccess";
import { Typography } from "@mui/material";

const InputFile = (props) => {
  const {
    authInfo,
    modalData,
    setModalData,
    setErrorMsg,
    setSuccessMsg,
    setLoading,
  } = useApp();
  const {
    register,
    handleSubmit,
    control,
    watch,
    resetField,
    formState: { errors },
  } = useForm();
  const watch_file = watch("file");
  const [responseUpload, setResponseUpload] = useState();
  const [alert, setAlert] = useState({
    open: false,
    type: CONSTANT.SUCCESS_MSG,
    msg: "",
    details: "",
  });
  const allowExt = [
    "pdf",
    "doc",
    "docx",
    "xls",
    "xlsx",
    "jpeg",
    "jpg",
    "png",
    "eml",
  ];

  useEffect(() => {
    if (watch_file && watch_file[0]?.name) {
      const fileName = watch_file[0].name.split(".").pop();
      const isAllowExt = allowExt.some((ext) => ext === fileName);
      if (!isAllowExt) {
        setAlert({
          open: true,
          type: CONSTANT.ERROR_MSG,
          msg: "Tipo de archivo no permitido",
          details: "Solo PDF, Excel, Word, JPG, JPEG, PNG",
        });
        resetField("file");
        return;
      }
      if (watch_file.size > 1e7) {
        setAlert({
          open: true,
          type: CONSTANT.ERROR_MSG,
          msg: "Tamaño de archivo no permitido",
          details: "El archivo supera el tamaño máximo permitido de 10Mb",
        });
        resetField("file");
      }
      const submitForm = handleSubmit(onSubmit);
      submitForm();
    }
  }, [watch_file]);

  const handleUpload = async (data) => {
    const formData = new FormData();
    formData.append("file", data.file[0]);
    console.log(data);
    for (const key in data) {
      if (key !== "file") {
        formData.append(key, data[key]);
      }
    }
    try {
      let createData = await uploadDocumentRequest(formData);
      setResponseUpload(createData);
      if (!createData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_document_save_error);
        setLoading && setLoading(false);
        return;
      }
    } catch (error) {
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const onSubmit = async (data) => {
    switch (modalData?.modalType) {
      default:
        //await handleUpload(data);
        await props.handleAddDocument(data);
        break;
    }
  };

  const [dragging, setDragging] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    if (!dragging) {
      setDragging(true);
    }
  };

  const handleDragLeave = () => {
    if (dragging) {
    setDragging(false);
    }
  };

  return (
    <>
      <form className="d-flex align-items-center gap-4">
        <div className="w-100 position-relative"
         onDragOver={handleDragOver}
         onDragLeave={handleDragLeave}
        >
          {props.nameDocument && props.nameDocument !== "" && (
            <label
              style={{
                position: "absolute",
                left: "86px",
                background: "#fff",
                height: "87%",
                top: "3px",
                width: "calc(100% - 90px)",
                paddingLeft: "8px",
                zIndex: "3",  
              }}
            >
              {watch_file && watch_file[0]?.name
                ? watch_file[0].name
                : props.nameDocument
                ? props.nameDocument
                : ""}
            </label>
          )}

          <Controller
            name={"file"}
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <>
                {dragging  &&  !Boolean(watch_file[0]?.name) ? (
                        <Typography
                          variant="body1"
                          element="span"
                          sx={{ display: "flex", justifyContent: "center", marginBottom: "-28px", position: "relative" }}
                        >
                          {" "}
                          Suelta los archivos aquí ...
                        </Typography>
                      ) : (
                        ""
                      )}
              <Form.Control
                className="form-control form-control2 form-control-sm"
                style={{
                backgroundColor: "transparent",
                  zIndex: "2",
                  position: "relative",
              }}
                type="file"
                {...register("file", { required: true })}
                accept="image/png, image/jpeg, image/jpg,.pdf,.doc,.docx,.xls,.xlsx, .eml"
                disabled={
                  ResourceAccess(true, "EXECUTIVERECORD:WRITE") === false ||
                  (ResourceAccess(true, "EXECUTIVERECORD:COMPLETE") === false &&
                  props.wStatus === "COMPLETE")
                }
                />
                </>
            )}
          />
        </div>
        <div className="d-flex align-items-center gap-1">
          <OverlayTrigger
            key={"top-tooltip-see"}
            placement="top"
            overlay={<Tooltip id={"top-tooltip-see"}>Ver documento</Tooltip>}
          >
            <a
              href="!#"
              variant="secondary"
              name="view"
              data-id={props.idDocumentByExp}
              data-name-document={props.nameDocument}
              data-extension={props.extension}
              onClick={props.handleOpenModal}
              className={props.idDocumentByExp ? "active" : "disabled"}
            >
              <i className="fa-solid fa-eye wd-ico ico-yelow m-2"></i>
            </a>
          </OverlayTrigger>
          <OverlayTrigger
            key={"top-tooltip-download"}
            placement="top"
            overlay={
              <Tooltip id={"top-tooltip-download"}>Descargar documento</Tooltip>
            }
          >
            <a
              variant="secondary"
              name={"download"}
              onClick={() =>
                getRequestDownloadDocumentClient(
                  props.idDocumentByExp,
                  props.nameDocument
                )
              }
              className={props.idDocumentByExp ? "active" : "disabled"}
            >
              <i className="fa-solid fa-file-arrow-down wd-ico ico-blue  m-2"></i>
            </a>
          </OverlayTrigger>

          <OverlayTrigger
            key={"top-tooltip-delete"}
            placement="top"
            overlay={
              <Tooltip id={"top-tooltip-delete"}>
                {properties.com_parval_label_button_delete_title}
              </Tooltip>
            }
          >
            <a
              href="!#"
              variant="secondary"
              name="deleteDoc"
              data-id={props.idDocumentByExp}
              data-dame-doc={props.nameDocument}
              onClick={props.handleOpenModal}
              className={
                ResourceAccess(true, "EXECUTIVERECORD:WRITE") === false ||
                (ResourceAccess(true, "EXECUTIVERECORD:COMPLETE") === false &&
                  props.wStatus === "COMPLETE") ||
                !!props.idDocumentByExp === false
                  ? "disabled"
                  : ""
              }
            >
              <i className="fa-solid fa-trash wd-ico ico-gray ico-red m-2"></i>
            </a>
          </OverlayTrigger>
        </div>
        <input
          hidden
          value={props.idExp}
          {...register("idExpedient", { required: true })}
        />
        <input
          hidden
          value={props.idDocument}
          {...register("idDocument", { required: true })}
        />
        <input
          hidden
          value={authInfo.username}
          {...register("user", { required: true })}
        />
      </form>
      {props.requiredDocuments && (
        <p className="text-danger">Documento requerido</p>
      )}
      {alert.open && (
        <MessageManager
          type={alert.type}
          msg={alert.msg}
          details={alert.details}
          callback={() => setAlert({ ...alert, open: false })}
        />
      )}
    </>
  );
};

export default InputFile;
