import React from "react";
import ReactDOM from "react-dom";
import { Modal } from "react-bootstrap";

const CustomModal = (props) => {
  return ReactDOM.createPortal(
    props.defaultmodal ? <DefaultModal {...props} /> : props.children,
    document.getElementById("modal")
  );
};

const DefaultModal = (props) => (
  <>
    <Modal
      show={props.isOpen}
      keyboard={false}
      size={props.modalSize}
      onHide={() => false}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <i className={`fa-solid ${props.modalTitleIcon}`}></i>{" "}
          {props.modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        <div className="row">
          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
            <button
              className="btn btn-warning col-7 p-1 me-md-2"
              id="actionModal"
              name={props.nameDoc}
              onClick={props.handleModal}
            >
              {props.buttonActionTitle}
            </button>
            <button
              type="button"
              className="btn btn-secondary col-7 p-1"
              id="cancelModal"
              onClick={props.handleCancelModal}
            >
              {props.buttonCancelTitle}
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  </>
);

export default CustomModal;
