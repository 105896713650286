import { InputLabel, styled } from "@mui/material";

const InputLabelForm = styled(InputLabel)(({ theme }) => ({
  transform: "translate(0, -14px) scale(0.75)",
  //transform: "translate(0, -21.5px) scale(0.75)",
  color: theme.palette.text.primary,
}));

const InputLabelToForm = (props) => {
  return <InputLabelForm {...props}>{props.children}</InputLabelForm>;
};

export default InputLabelToForm;
