import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  Grid,
  Typography,
  Autocomplete,
  useTheme,
  TextField,
  Button,
  Stack,
  Divider,
  Dialog,
  Box,
  Paper,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  IconButton,
  FormHelperText,
} from "@mui/material";
import InputLabelToForm from "../utils/InputLabelToForm";
import FancyTooltip from "../utils/FancyTooltip";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { format } from "date-fns";
import { AngleRightIcon } from "../svgicons/SvgIcons";

import { useNavigate, useParams } from "react-router-dom";

import {
  SVFindByIdRequest,
  SVUpdateRequest,
  SVUpdateRequestStatus,
  SVSaveRequest,
  SaveNoteRequest,
  DeleteNoteRequest,
  UpdateNoteRequest,
} from "../../services/record/RecordService";
import {
  getAllOperationType,
  uploadDocumentRequest,
  deleteDocumentRequest,
  getDocumentByOperationTypeByExp,
  getDocumentByOperationType,
  getNotesByRecord,
} from "../../services/DocumentService";
import { sendNotification } from "../../services/NotificationService";
import { useApp } from "../../hooks/useApp";

import { OverlayTrigger } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constant";
import {
  validateFieldStyle,
  validateIdentificationPH,
  parseDate,
} from "../../utils/UtilsFunction";
import MessageManager from "../utils/MessageManager";
import InputFile from "./InputFile";
import DeleteModal from "../utils/DeleteModal";
import FileViewModal from "../utils/FileViewModal";
import CustomDialog from "../utils/CustomDialog";
import AddObservation from "../utils/AddObservation";
import CustomPagination from "../../components/utils/CustomPagination";
import ResourceAccess from "../security/ResourceAccess";
import { requestExternalApiCustomer } from "../../services/externalapi/ExternalApi";
import SearchCustomerExternalApi from "../utils/SearchCustomerExternalApi";
import Loading from "../utils/Loading";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

/* interface AddOrUpdateDocument { 
  id?: number;
  status: string;
  createdDate: Date;
  modifiedDate: Date;
  modifiedBy: string;
  clientCode: string;
  clientId: string;
  clientName: string;
  personType: number;
  operationType: number;
  accounts: {
    dealTicket: string;
    dealDate: Date | null,
    liquiDate: Date | null,
    operationType: string;
    currency: string;
    expedient: number;
  }
}
 */

const accountSchema = {
  dealTicket: Yup.string().required("Campo es obligatorio"),
  dealDate: Yup.date().required("Campo es obligatorio"),
  liquiDate: Yup.date().required("Campo es obligatorio"),
  operationType: Yup.string().required("Campo es obligatorio"),
  currency: Yup.string().required("Campo es obligatorio"),
};

const validation = Yup.object().shape({
  id: Yup.string(),
  status: Yup.string(),
  clientCode: Yup.string().required("Campo es obligatorio"),
  clientId: Yup.string().required("Campo es obligatorio"),
  clientName: Yup.string().required("Campo es obligatorio"),
  personType: Yup.string().required("Campo es obligatorio"),
  operationType: Yup.number().required("Campo es obligatorio"),
  accounts: Yup.array().of(Yup.object().shape(accountSchema)),
});

const OperationForm = () => {
  const theme = useTheme();
  registerLocale("es", es);
  const { wId } = useParams();
  const navigate = useNavigate();
  const {
    authInfo,
    isLoading,
    setLoading,
    errorMsg,
    setErrorMsg,
    setSuccessMsg,
    modalData,
    setModalData,
    operationTypeData,
  } = useApp();
  const [formData, setFormData] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  /* const [operationTypeData, setOperationTypeData] = useState(); */
  const [documentByOperationType, setDocumentByOperationType] = useState();
  const [allDocuments, setAllDocuments] = useState();
  const [applyStyleForm, setApplyStyleForm] = useState(false);
  const [requiredDocuments, setRequiredDocuments] = useState(false);
  const [addAdminObservation, setAddAdminObservation] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [notesData, setNotesData] = useState([]);
  const [recordData, setRecordData] = useState({ totalElements: 0, size: 0 });
  const [page, setPage] = useState(0);

  let newF = { newField: false };
  let objF;
  const [alert, setAlert] = useState({
    open: false,
    type: "success",
    msg: "",
    details: "",
  });

  useLayoutEffect(() => {
    const dataInit = async () => {
      //await handleFetchOperationType();
      if (wId) {
        await handleFetchByID(wId);
      }
    };
    dataInit();
    // eslint-disable-next-line
  }, []);

  /* const handleFetchOperationType = async () => {
    try {
      let operationAllData = await getAllOperationType();
      if (operationAllData) {
        setOperationTypeData(operationAllData.content);
      }
    } catch (error) {
      setLoading(false);
      setErrorMsg(error.message);
    }
  };
 */
  const handleFetchByID = async (id) => {
    setLoading(true);
    try {
      let documentsId = await getDocumentByOperationType();
      if (documentsId) {
        setAllDocuments(documentsId);
      }
      let recordData = await SVFindByIdRequest(id);
      if (recordData) {
        const formattedAccounts = recordData.content.accounts.map((obj) => ({
          ...obj,
          dealDate: new Date(parseDate(obj.dealDate)),
          liquiDate: new Date(parseDate(obj.liquiDate)),
        }));
        setFormData({
          ...recordData.content,
          accounts: formattedAccounts,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setErrorMsg(error.message);
    }
  };

  const handleFetctDocumentByOperation = async (id, idOperation) => {
    try {
      let operationAllData = await getDocumentByOperationTypeByExp(
        id,
        idOperation
      );
      if (operationAllData) {
        objF =
          operationAllData &&
          operationAllData.length > 0 &&
          operationAllData.map((d) => ({ ...d, ...newF }));
        setDocumentByOperationType(objF);
      }
    } catch (error) {
      setLoading(false);
      setErrorMsg(error.message);
    }
  };

  const defaultValues = {
    id: formData?.id ? formData.id : 0,
    status: "INCOMPLETE",
    createdDate: new Date(),
    modifiedDate: new Date(),
    modifiedBy: authInfo.username,
    clientCode: formData?.clientCode ? formData.clientCode : "",
    clientId: formData?.clientId ? formData.clientId : "",
    clientName: formData?.clientName ? formData.clientName : "",
    personType: formData?.personType ? formData.personType : "",
    operationType: formData?.operationType ? formData.operationType : 0,
    accounts: (formData?.accounts && formData.accounts) ?? [
      {
        dealTicket: "",
        dealDate: null,
        liquiDate: null,
        operationType: "",
        currency: "",
        expedient: 0,
      },
    ],
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
    control,
    reset,
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  const {
    fields: accountsField,
    append: accountsAppend,
    remove: accountsRemove,
  } = useFieldArray({
    control,
    name: "accounts",
    rules: {
      minLength: 1,
    },
  });
  const wOperationType = watch("operationType");
  const wStatus = watch("status");
  const wequalDate = watch("equalDate", false);
  const wPersonType = watch("personType", "0");

  useEffect(() => {
    reset(defaultValues);
  }, [formData]);

  useEffect(() => {
    const documentData = async () => {
      if (wId && wOperationType !== 0) {
        await handleFetctDocumentByOperation(wId, wOperationType);
      }
    };
    const getNotes = async () => {
      if (wId && wOperationType !== 0) {
        await handleFetchNotes(wId, page);
      }
    };
    documentData();
    getNotes();
  }, [wOperationType]);

  const handleFetchNotes = async (wId, page) => {
    try {
      let notesId = await getNotesByRecord(wId, page, 5);
      if (notesId) {
        setNotesData(notesId);
        setRecordData(notesId);
      }
    } catch (error) {
      setErrorMsg(error.message);
    }
  };

  const handleSendNotification = async (data) => {
    setLoading(true);
    try {
      let dataNotification = await sendNotification({
        ...data,
        roles: 10,
      });

      if (!dataNotification) {
        setErrorMsg("Error enviando notificación a contabilidad");
        setLoading(false);
        return;
      }
      setLoading(false);
    } catch (error) {
      setErrorMsg(error.message);
      setLoading(false);
    }
  };

  const handleAdd = async (data) => {
    setLoading(true);
    console.log(data);
    const formattedAccounts = data.accounts.map((objeto) => ({
      ...objeto,
      dealDate: format(new Date(objeto.dealDate), "dd/MM/yyyy"),
      liquiDate: format(new Date(objeto.liquiDate), "dd/MM/yyyy"),
    }));
    console.log(formattedAccounts);
    try {
      let addData = await SVSaveRequest({
        ...data,
        accounts: formattedAccounts,
        createdBy: authInfo?.username,
        operationType: formattedAccounts[0].operationType,
      });

      if (!addData || !addData.id) {
        setLoading(false);
        return;
      }
      setLoading(false);
      setSuccessMsg(properties.com_parval_label_request_save);
      navigate(`/createClient/${addData.id}`);
      await handleFetchByID(addData.id);
      await handleFetctDocumentByOperation(
        addData.id,
        addData.accounts[0].operationType
      );
      // await handleFetctDocumentByOperation(addData.id, wOperationType);
      await handleFetchNotes(addData.id, 0);
    } catch (error) {
      setErrorMsg(error.message);
      setLoading(false);
    }
  };

  const handleUpdate = async (data) => {
    setLoading(true);
    const formattedAccounts = data.accounts.map((objeto) => ({
      ...objeto,
      dealDate: format(new Date(objeto.dealDate), "dd/MM/yyyy"),
      liquiDate: format(new Date(objeto.liquiDate), "dd/MM/yyyy"),
    }));
    try {
      let updateData = await SVUpdateRequest({
        ...data,
        accounts: formattedAccounts,
        modifiedBy: authInfo?.username,
        operationType: formattedAccounts[0].operationType,
      });
    } catch (error) {
      setErrorMsg(error.message);
      setLoading(false);
      console.log(error);
    }
  };

  const handleAddDocument = async (data) => {
    let fileName = data.file[0].name.split(/\.(?=[^.]*$)/);
    fileName[0] =
      fileName[0].replace(/[^A-Z0-9.]+/gi, "_") + Math.round(Date.now() / 1000);
    const formData = new FormData();
    formData.append("file", data.file[0], fileName.join("."));

    for (const key in data) {
      if (key !== "file") {
        formData.append(key, data[key]);
      }
    }
    try {
      let createData = await uploadDocumentRequest(formData);
      if (!createData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_document_save_error);
        setLoading && setLoading(false);
        return;
      }
      await handleFetctDocumentByOperation(wId, wOperationType);
    } catch (error) {
      setErrorMsg && setErrorMsg(error);
    }
  };

  const handleDeleteDoc = async (id) => {
    handleCancelModal();
    setLoading(true);
    try {
      let deleteData = await deleteDocumentRequest(id);
      if (!deleteData) {
        setErrorMsg(properties.com_parval_label_document_delete_error);
        setLoading(false);
        return;
      }
      setLoading(false);
      setSuccessMsg(properties.com_parval_label_request_delete);
      await handleFetchByID(wId);
      await handleFetctDocumentByOperation(wId, wOperationType);
    } catch (error) {
      setErrorMsg(error.message);
    }
  };

  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData({
        modalOpen: false,
        modalType: null,
        modalObject: null,
      });
    }
  }, [modalData, errorMsg, setModalData]);

  const handleOpenModal = async (event) => {
    event.preventDefault();
    const modalAction = event.currentTarget.name || event.currentTarget.id;
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");
    const nameDocument = event.currentTarget.getAttribute("data-dame-doc");

    const nameD = event.currentTarget.getAttribute("data-name-document");
    const extensionD = event.currentTarget.getAttribute("data-extension");

    if (modalAction === "state") {
      await handleFetchByID(id);
    }
    if (modalAction === "deleteDoc") {
      //object = recordData.content.find((p) => p.id === parseInt(id));
      object = { id: id, document: nameDocument };
    }

    if (modalAction === "view") {
      object = { id: id, nameD: nameD, ext: extensionD };
    }
    if (modalAction === "deleteObservation") {
      object = { id: id };
    }

    //open modal
    setModalData({
      ...modalData,
      modalOpen: true,
      modalType: modalAction,
      modalObject: object,
    });
  };

  const handleCancelModal = () => {
    setAddAdminObservation(false);
    setUpdateData(false);
    setModalData({
      ...modalData,
      modalOpen: false,
      modalType: null,
      modalObject: null,
    });
  };

  const addField = async (e) => {
    const nameDoc = e.currentTarget.getAttribute("data-name");
    const newDocumentByExp = documentByOperationType.map((d) => {
      if (d.name !== nameDoc) return d;
      return { ...d, newField: true };
    });
    setDocumentByOperationType(newDocumentByExp);
  };

  const handleAddObservation = async (data) => {
    handleCancelModal();
    setLoading(true);
    try {
      let addData = await SaveNoteRequest(data);

      if (!addData || !addData.id) {
        setErrorMsg(properties.com_parval_label_record_save_error);
        setLoading(false);
        return;
      }
      setLoading(false);
      setSuccessMsg(properties.com_parval_label_request_save);
      await handleFetchNotes(wId, page);
    } catch (error) {
      setErrorMsg(error.message);
      setLoading(false);
    }
  };

  const handleDeleteObservation = async (id, user) => {
    handleCancelModal();
    setLoading(true);
    try {
      let deleteData = await DeleteNoteRequest(id, user);
      if (!deleteData) {
        setErrorMsg("Error borrando observación");
        setLoading(false);
        return;
      }
      setLoading(false);
      setSuccessMsg(properties.com_parval_label_request_delete);
      if (wId) {
        await handleFetchNotes(wId, 0);
      }
    } catch (error) {
      setErrorMsg(error.message);
    }
  };

  const handleValidateChangeState = async (data) => {
    if (
      documentByOperationType &&
      documentByOperationType.some((d) => d.required === true && d.uploaded < 1)
    ) {
      setErrorMsg && setErrorMsg("Hay algunos documentos requeridos vacios");
      setRequiredDocuments(true);
    } else {
      setAddAdminObservation(true);
    }
  };

  const handleChangeState = async (data) => {
    handleCancelModal();
    setLoading(true);
    //console.log(formData);
    try {
      let addData = await SaveNoteRequest(data);
      if (!addData || !addData.id) {
        setErrorMsg(properties.com_parval_label_record_save_error);
        setLoading(false);
        return;
      }
      await handleFetchNotes(wId, page);
      await handleSendNotification({
        ...data,
        name: formData.clientName,
      });

      const formattedAccounts = formData.accounts.map((objeto) => ({
        ...objeto,
        dealDate: format(new Date(objeto.dealDate), "dd/MM/yyyy"),
        liquiDate: format(new Date(objeto.liquiDate), "dd/MM/yyyy"),
      }));
      const formDataWithoutNulls = Object.fromEntries(
        Object.entries(formData).filter(([_, value]) => value !== null)
      );
      console.log(formDataWithoutNulls);
      let changeState = await SVUpdateRequestStatus({
        //...formData,
        ...formDataWithoutNulls,
        accounts: formattedAccounts,
        status: "COMPLETE",
        modifiedBy: authInfo?.username,
      });

      if (!changeState) {
        setErrorMsg(properties.com_parval_label_record_update_error);
        setLoading(false);
        return;
      }
      setLoading(false);
      setSuccessMsg(properties.com_parval_label_request_update);
      await handleFetchByID(wId);
    } catch (error) {
      setErrorMsg(error.message);
      setLoading(false);
    }
  };

  const pagination = async (pageNumber) => {
    let customPage = pageNumber - 1;
    if (customPage !== page) {
      setPage(customPage);
      await handleFetchNotes(wId, customPage);
    }
  };

  const handleChangeToDoObs = async (data) => {
    setLoading(true);
    const noteId = data.currentTarget.getAttribute("data-id");
    const findNoteData =
      notesData.content &&
      notesData.content.find((n) => n.id === parseInt(noteId));
    try {
      let addCheck = await UpdateNoteRequest({
        ...findNoteData,
        toDo: data.target.checked,
      });

      if (!addCheck) {
        setErrorMsg("Error en proceso de guardar observación");
        setLoading(false);
        return;
      }
      setLoading(false);
      setSuccessMsg(properties.com_parval_label_request_save);
      await handleFetchNotes(wId, page);
    } catch (error) {
      setErrorMsg(error.message);
      setLoading(false);
    }
  };

  const handleSearchCustomer = async (data) => {
    setLoading(true);
    try {
      const response = await requestExternalApiCustomer(data.id, data.cual);
      if (response) {
        if (response.Nmfull === "") {
          setErrorMsg(
            `No existen datos que coincidan con el código <strong>${data.id}</strong>`
          );
        } else {
          handleCancelModal();
          setValue("clientName", response.Nmfull);
          setValue("clientId", response.Rif.slice(3, response.Rif.length));
          setValue("clientCode", response.Id);
        }
      }
      setLoading(false);
    } catch (error) {
      handleCancelModal();
      setLoading(false);
      setErrorMsg(error.message);
    }
  };

  const onSubmit = async (data, e) => {
    const typeAction = e.target.name;
    console.log(e.target.checked);
    switch (typeAction) {
      case "initSave":
        await handleAdd(data);
        break;
      case "createDoc":
        await handleAddDocument(data);
        break;
      case "deleteDoc":
        await handleDeleteDoc(modalData.modalObject.id, authInfo.username);
        break;
      case "save":
        setUpdateData(true);
        await handleUpdate(data);
        break;
      case "addObservation":
        await handleAddObservation(data);
        break;
      case "toDo":
        await handleChangeToDoObs(data);
        break;
      case "deleteObservation":
        await handleDeleteObservation(
          modalData.modalObject.id,
          authInfo.username
        );
        break;
      case "customerSearch":
        await handleSearchCustomer(data);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <Box
        sx={{ px: 3.75, pt: 12, pb: 0, mr: "auto", ml: "auto", mb: 12 }}
        maxWidth="lg"
      >
        <Paper
          sx={{
            padding: {
              xs: `${theme.spacing(10)} ${theme.spacing(5)}`,
              md: theme.spacing(10),
            },
            pb: theme.spacing(2),
            borderRadius: theme.spacing(4),
          }}
        >
          <form>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h3" color="greyDue.dark" sx={{ mb: 4 }}>
                {wId
                  ? properties.com_parval_label_record_update_title
                  : properties.com_parval_label_record_add_title}
              </Typography>
            </Stack>
            <Grid container spacing={6} maxWidth="lg" sx={{ pb: 8 }}>
              <Grid item md={9} sm={6} xs={12}>
                <Typography
                  variant="h5"
                  color="greyDue.dark"
                  sx={{ fontWeight: 400, fontSize: "1.25rem" }}
                >
                  <AngleRightIcon
                    sx={{
                      marginRight: "0px",
                      color: "primary.main",
                      transform: "translateY(-3px)",
                      fontSize: "1.25rem",
                    }}
                  />{" "}
                  Datos del cliente
                </Typography>
              </Grid>
              <Grid
                item
                md={3}
                sm={6}
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                {!wId && (
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    name="customerSearch"
                    onClick={handleOpenModal}
                  >
                    Buscar datos
                  </Button>
                )}
                <Typography variant="body1" color="initial">
                  <strong>Estado: </strong>
                  {formData?.status ? formData.status : "Incompleto"}
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ marginBottom: "50px" }} />
            <Grid container spacing={6} maxWidth="lg" sx={{ pb: 8 }}>
              <Grid item md={3} sm={6} xs={12}>
                <Controller
                  name={"clientCode"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Código del cliente"
                      type="text"
                      variant="standard"
                      fullWidth
                      value={value}
                      {...register("clientCode")}
                      error={errors.clientCode && Boolean(errors.clientCode)}
                      helperText={
                        errors.clientCode && errors.clientCode.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Controller
                  name={"clientId"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Identificación"
                      type="text"
                      variant="standard"
                      fullWidth
                      value={value}
                      {...register("clientId")}
                      error={errors.clientId && Boolean(errors.clientId)}
                      helperText={errors.clientId && errors.clientId.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Controller
                  name={"clientName"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Nombre"
                      type="text"
                      variant="standard"
                      fullWidth
                      value={value}
                      {...register("clientName")}
                      error={errors.clientName && Boolean(errors.clientName)}
                      helperText={
                        errors.clientName && errors.clientName.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Controller
                  name={"personType"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      variant="standard"
                      error={errors?.personType ?? false}
                    >
                      <InputLabel>Tipo de persona</InputLabel>
                      <Select
                        id="personType"
                        value={value}
                        {...register("personType")}
                      >
                        <MenuItem value="">Seleccione</MenuItem>
                        <MenuItem value="F">
                          {properties.com_parval_label_persontype_physical}
                        </MenuItem>
                        <MenuItem value="J">
                          {properties.com_parval_label_persontype_legal}
                        </MenuItem>
                      </Select>
                      <FormHelperText error={true}>
                        {errors?.personType?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
            <Divider sx={{ mt: "10px", mb: "30px" }} />
            {accountsField.map((field, index) => {
              return (
                <div key={index}>
                  <Grid container spacing={6} maxWidth="lg" sx={{ pb: 8 }}>
                    <Grid item md={3} sm={6} xs={12}>
                      <Controller
                        name={`accounts.${index}.operationType`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <FormControl
                            fullWidth
                            variant="standard"
                            error={
                              errors?.accounts?.[index]?.operationType ?? false
                            }
                          >
                            <InputLabel>Tipo de operación</InputLabel>
                            <Select
                              labelId="operationType"
                              id="operationType"
                              value={value}
                              label="Tipo de operación"
                              {...register(`accounts.${index}.operationType`)}
                            >
                              <MenuItem value="">Seleccione</MenuItem>
                              {operationTypeData &&
                                operationTypeData.map((op, i) => (
                                  <MenuItem key={i} value={op.id}>
                                    {op.name}
                                  </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText error={true}>
                              {
                                errors?.accounts?.[index]?.operationType
                                  ?.message
                              }
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                      <Controller
                        name={`accounts.${index}.dealTicket`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            label="Ticket"
                            type="text"
                            variant="standard"
                            fullWidth
                            value={value}
                            {...register(`accounts.${index}.dealTicket`)}
                            error={
                              errors?.accounts?.[index]?.dealTicket && true
                            }
                            helperText={
                              errors?.accounts?.[index]?.dealTicket?.message
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                      <Controller
                        name={`accounts.${index}.currency`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <FormControl
                            fullWidth
                            variant="standard"
                            error={errors?.accounts?.[index]?.currency ?? false}
                          >
                            <InputLabel>Moneda</InputLabel>
                            <Select
                              labelId="currency"
                              id="currency"
                              value={value}
                              label="Moneda"
                              {...register(`accounts.${index}.currency`)}
                            >
                              <MenuItem value="">Seleccione</MenuItem>
                              <MenuItem value="USD">USD</MenuItem>
                              <MenuItem value="DOP">DOP</MenuItem>
                            </Select>
                            <FormHelperText error={true}>
                              {errors?.accounts?.[index]?.currency?.message}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                      <Controller
                        name={`accounts.${index}.dealDate`}
                        control={control}
                        render={({ field, ...props }) => (
                          <FormControl
                            fullWidth
                            sx={{ marginTop: "12px" }}
                            error={errors?.accounts?.[index]?.dealDate ?? false}
                          >
                            <InputLabelToForm>Fecha de pacto</InputLabelToForm>
                            <DatePicker
                              locale="es"
                              className={`form-control ${field.value}`}
                              /* selected={
                                field.value && new Date(parseDate(field.value))
                              }
                              selected={
                                field.value &&
                                format(new Date(field.value), "dd/MM/yyyy")
                              } */
                              selected={field.value}
                              onChange={(date) => {
                                field.onChange(date);
                              }}
                              dateFormat={CONSTANT.DATE_FORMAT}
                            />
                            <FormHelperText error={true}>
                              {errors?.accounts?.[index]?.dealDate?.message}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12} sx={{ display: "flex" }}>
                      <div style={{ width: "100%" }}>
                        <Controller
                          name={`accounts.${index}.liquiDate`}
                          control={control}
                          render={({ field, ...props }) => (
                            <FormControl
                              fullWidth
                              sx={{ marginTop: "12px" }}
                              disabled={
                                ResourceAccess(
                                  true,
                                  "EXECUTIVERECORD:WRITE"
                                ) === false ||
                                (ResourceAccess(
                                  true,
                                  "EXECUTIVERECORD:COMPLETE"
                                ) === false &&
                                  wStatus === "COMPLETE")
                              }
                              error={
                                errors?.accounts?.[index]?.liquiDate ?? false
                              }
                            >
                              <InputLabelToForm>
                                Fecha de liquidación
                              </InputLabelToForm>
                              <DatePicker
                                locale="es"
                                className={`form-control ${field.value}`}
                                /* selected={
                                field.value && new Date(parseDate(field.value))
                              } */
                                selected={field.value}
                                onChange={(date) => {
                                  field.onChange(date);
                                }}
                                dateFormat={CONSTANT.DATE_FORMAT}
                              />
                              <FormHelperText error={true}>
                                {errors?.accounts?.[index]?.liquiDate?.message}
                              </FormHelperText>
                            </FormControl>
                          )}
                        />
                      </div>
                      {index > 0 && index === accountsField.length - 1 && (
                        <FancyTooltip title="Remover" placement="top">
                          <IconButton
                            aria-label="delete"
                            color="primary"
                            onClick={() => {
                              accountsRemove(index);
                            }}
                          >
                            <RemoveCircleIcon sx={{ fontSize: "1.563rem" }} />
                          </IconButton>
                        </FancyTooltip>
                      )}
                    </Grid>
                  </Grid>
                  <Divider sx={{ mt: "10px", mb: "30px" }} />
                </div>
              );
            })}
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              sx={{ mb: 2 }}
            >
              <Button
                type="button"
                variant="outlined"
                onClick={() => {
                  accountsAppend({
                    dealTicket: "",
                    dealDate: null,
                    liquiDate: null,
                    operationType: "",
                    currency: "",
                    expedient: 0,
                  });
                }}
              >
                <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar cuenta
              </Button>
            </Grid>
            <Grid container spacing={6} maxWidth="lg" sx={{ pt: 6, pb: 8 }}>
              {wId && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-end" },
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    data-name="observation"
                    onClick={() => setOpenDialog(true)}
                  >
                    Ver observaciones
                  </Button>
                </Grid>
              )}
            </Grid>

            {wOperationType !== "0" && wId > 0 && (
              <Grid container spacing={6} maxWidth="lg" sx={{ pb: 8 }}>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    color="greyDue.dark"
                    sx={{ fontWeight: 400, fontSize: "1.25rem" }}
                  >
                    <AngleRightIcon
                      sx={{
                        marginRight: "0px",
                        color: "primary.main",
                        transform: "translateY(-2px)",
                        fontSize: "1.25rem",
                      }}
                    />{" "}
                    Categoría de Documentos
                  </Typography>
                  <Divider />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", flexDirection: "column" }}
                  className="table-responsive"
                >
                  {documentByOperationType &&
                    documentByOperationType.map((d) => (
                      <table key={d.id}>
                        <tbody>
                          <tr>
                            <td className="col-10">
                              <label
                                htmlFor={"formFile" + d.id}
                                className="form-label"
                              >
                                {"Categoría de " + d.name}
                                {d.required ? (
                                  <React.Fragment>
                                    <span className="text-danger"> *</span>
                                  </React.Fragment>
                                ) : (
                                  ""
                                )}
                              </label>
                              {d?.uploaded?.length > 0 ? (
                                <>
                                  {d.uploaded.map((dup, i) => (
                                    <InputFile
                                      key={i}
                                      idExp={wId}
                                      idDocumentByExp={dup.id}
                                      idDocument={dup.document.id}
                                      nameDocument={dup.originalName}
                                      extension={dup.extension}
                                      handleAddDocument={handleAddDocument}
                                      handleOpenModal={handleOpenModal}
                                      wStatus={wStatus}
                                      //requiredDocuments={d.required}
                                    />
                                  ))}
                                  {d.newField && (
                                    <InputFile
                                      idExp={wId}
                                      idDocument={d.id}
                                      handleAddDocument={handleAddDocument}
                                      handleOpenModal={handleOpenModal}
                                      wStatus={wStatus}
                                      requiredDocuments={
                                        d.required && requiredDocuments
                                      }
                                    />
                                  )}
                                  <OverlayTrigger
                                    key={"top-tooltip-add"}
                                    placement="top"
                                    overlay={
                                      <Tooltip id={"top-tooltip-add"}>
                                        Agregar otro documento
                                      </Tooltip>
                                    }
                                  >
                                    <a
                                      variant="secondary"
                                      name={"add"}
                                      data-name={d.name}
                                      onClick={addField}
                                      style={{
                                        display: "block",
                                        marginLeft: "auto",
                                        marginRight: "0",
                                        width: "fit-content",
                                      }}
                                      className={
                                        d.newField ||
                                        ResourceAccess(
                                          true,
                                          "EXECUTIVERECORD:WRITE"
                                        ) === false ||
                                        (ResourceAccess(
                                          true,
                                          "EXECUTIVERECORD:COMPLETE"
                                        ) === false &&
                                          wStatus === "COMPLETE")
                                          ? "disabled"
                                          : ""
                                      }
                                    >
                                      <i className="fa-solid fa-circle-plus wd-ico ico-green   m-2"></i>
                                    </a>
                                  </OverlayTrigger>
                                </>
                              ) : (
                                <InputFile
                                  idExp={wId}
                                  idDocument={d.id}
                                  handleAddDocument={handleAddDocument}
                                  handleOpenModal={handleOpenModal}
                                  wStatus={wStatus}
                                  requiredDocuments={
                                    d.required && requiredDocuments
                                  }
                                />
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ))}
                </Grid>
              </Grid>
            )}

            <Stack
              direction="row"
              justifyContent="center"
              spacing={3}
              sx={{ mt: 4 }}
            >
              <Button
                variant="contained"
                color="primary"
                type="button"
                name={wId ? "save" : "initSave"}
                onClick={handleSubmit(onSubmit)}
                disabled={!ResourceAccess(true, "EXECUTIVERECORD:WRITE")}
              >
                Guardar
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="button"
                name="updateStatus"
                onClick={handleValidateChangeState}
                disabled={
                  ResourceAccess(true, "EXECUTIVERECORD:WRITE") === false ||
                  wStatus === "COMPLETE" ||
                  !wId
                }
              >
                Guardar y enviar
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => navigate("/")}
              >
                Cancelar
              </Button>
            </Stack>
          </form>
        </Paper>
      </Box>
      {alert.open && (
        <MessageManager
          type={alert.type}
          msg={alert.msg}
          details={alert.details}
          callback={() => setAlert({ ...alert, open: false })}
        />
      )}
      {modalData?.modalType === "deleteDoc" && (
        <DeleteModal
          isOpen={modalData?.modalOpen}
          data={`${modalData?.modalObject.document}`}
          modalSize=""
          handleModal={handleSubmit(onSubmit)}
          handleCancelModal={handleCancelModal}
          nameDoc="deleteDoc"
        />
      )}
      {modalData?.modalType === "deleteObservation" && (
        <DeleteModal
          isOpen={modalData?.modalOpen}
          data={`${modalData?.modalObject.id}`}
          modalSize=""
          handleModal={handleSubmit(onSubmit)}
          handleCancelModal={handleCancelModal}
          nameDoc="deleteObservation"
        />
      )}
      {modalData?.modalType === "view" && (
        <FileViewModal
          isOpen={modalData?.modalOpen}
          document={modalData?.modalObject}
          handleCancelModal={handleCancelModal}
        />
      )}
      <CustomDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        fullWidth
        maxWidth={"lg"}
        title={"Histórico de observaciones"}
      >
        <button
          type="button"
          className="btn btn-warning px-3 me-md-2"
          name="addObservation"
          onClick={handleOpenModal}
        >
          Agregar Observaciones
        </button>
        <div className="col-12 table-responsive pt-4">
          <table className="table">
            <thead className="style-head text-center">
              <tr>
                <th>Id</th>
                <th>Descripción</th>
                <th>Autor</th>
                <th>Fecha de creación</th>
                <td>Por hacer</td>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody className="style-body text-center">
              {notesData &&
                notesData.content &&
                notesData.content.map((r) => (
                  <tr key={r.id}>
                    <td className="align-middle">{r.id}</td>
                    <td className="align-middle">{r.description}</td>
                    <td className="align-middle">{r.createdBy}</td>
                    <td className="align-middle">
                      {new Date(r.createdAt).toLocaleDateString("ES")}
                    </td>
                    <td className="align-middle">
                      <form>
                        <input
                          type="checkbox"
                          name="toDo"
                          checked={r.toDo}
                          data-id={r.id}
                          onChange={handleChangeToDoObs}
                        />
                      </form>
                    </td>
                    <td className="table-action">
                      <OverlayTrigger
                        key={"top-tooltip-delete" + r.id}
                        placement="top"
                        overlay={
                          <Tooltip id={"top-tooltip-delete" + r.id}>
                            {properties.com_parval_label_button_delete_title}
                          </Tooltip>
                        }
                      >
                        <button
                          href="!#"
                          variant="secondary"
                          name="deleteObservation"
                          data-id={r.id}
                          onClick={handleOpenModal}
                          style={{ border: 0, background: "transparent" }}
                          disabled={
                            ResourceAccess(true, "EXECUTIVERECORD:WRITE") ===
                              false ||
                            (ResourceAccess(
                              true,
                              "EXECUTIVERECORD:COMPLETE"
                            ) === false &&
                              wStatus === "COMPLETE")
                          }
                        >
                          <i className="fa-solid fa-trash m-2 ico-red"></i>
                        </button>
                      </OverlayTrigger>
                    </td>
                  </tr>
                ))}
              {(!notesData || notesData?.content?.length <= 0) && (
                <tr>
                  <td colSpan="11">
                    No existen observaciones para este expediente
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <CustomPagination
                  activePage={page}
                  itemsCountPerPage={notesData.size}
                  totalItemsCount={notesData.totalElements}
                  pageRangeDisplayed={5}
                  innerClass="pagination justify-content-end"
                  itemClass="page-item"
                  linkClass="page-link"
                  callback={pagination}
                />
              </div>
            </div>
          </div>
        </div>
        {modalData?.modalType === "addObservation" && (
          <AddObservation
            isOpen={modalData?.modalOpen}
            modalType={modalData.modalType}
            handleAddObservation={handleAddObservation}
            handleCancelModal={handleCancelModal}
            wId={wId}
          />
        )}
      </CustomDialog>

      <AddObservation
        isOpen={addAdminObservation}
        modalType="updateStatus"
        handleAddObservation={handleChangeState}
        handleCancelModal={handleCancelModal}
        wId={wId}
      />
      <AddObservation
        isOpen={updateData}
        modalType="addObservation"
        handleAddObservation={handleAddObservation}
        handleCancelModal={() => setUpdateData(false)}
        wId={wId}
      />
      {modalData?.modalType === "customerSearch" && (
        <SearchCustomerExternalApi
          isOpen={modalData?.modalOpen}
          handleCancelModal={handleCancelModal}
          handleSearchCustomer={handleSearchCustomer}
        />
      )}
    </>
  );
};

export default OperationForm;
