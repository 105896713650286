import React from "react";
import { properties } from "../../utils/Properties_es";
import Modal from "./CustomModal";

const DeleteModal = ({
  isOpen,
  modalSize,
  data,
  handleModal,
  handleCancelModal,
  nameDoc,
}) => (
  <Modal
    defaultmodal={true}
    isOpen={isOpen}
    modalSize={modalSize}
    modalTitle={properties.com_parval_label_modal_delete_title}
    modalTitleIcon="fa-trash ico-check-two"
    handleModal={handleModal}
    handleCancelModal={handleCancelModal}
    buttonActionTitle={properties.com_parval_label_button_accept_title}
    buttonCancelTitle={properties.com_parval_label_button_cancel_title}
    nameDoc={nameDoc}
  >
    <div className="row">
      <div className="col-12 text-center text-modal">
        <label>
          ¿Está seguro de eliminar{" "}
          <strong style={{ wordBreak: "break-word" }}>{data}</strong>?
        </label>
      </div>
    </div>
  </Modal>
);

export default DeleteModal;
