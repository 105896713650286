import { useApp } from "../../hooks/useApp";

const ResourceAccess = (isCode, pathOrCode) =>
  //children,
  {
    const { authInfo } = useApp();

    //validate authority
    const authority =
      authInfo?.userRole?.authorityRoles &&
      authInfo?.userRole?.authorityRoles.length > 0
        ? authInfo?.userRole?.authorityRoles.find((a) =>
            isCode
              ? a.authority.code ===
                pathOrCode /* || a.authority.code === pathOrCodeOpt */
              : a.authority.path === pathOrCode
          )
        : null;

    if (authority) {
      return true;
    } else {
      return false;
    }
  };

export default ResourceAccess;
