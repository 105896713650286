import { CONSTANT } from "./Constant";

export const URL = {
  APPLOGIN_REQUEST: CONSTANT.DISPATCHER_SECURITY_PREFIX + "/oauth/token",
  SESSIONVALIDATE_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/validatesession",
  /** PARVAL EXTERNAL API */
  CLIENTACCOUNTS_REQUEST: "/user/cuentas",

  /* URL RECORD */
  REQUEST_RECORD_LIST: (page, size) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/expedients/${page}/${size}`,
  REQUEST_RECORD: (id) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/expedients/${id ? id : ""}`,
  REQUEST_RECORD_DELETE: (id, user) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/expedients/${id}/?user=${user}`,
  REQUEST_RECORD_NOTES: (id, idNote) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/expedients/${id}/notes/${idNote ? idNote : ""}`,

  /*Operation type */
  ALL_OPER_TYPE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/operation-types/0/100`,
  DOCUMENT_BY_OPERATION:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/operation-types-document/`,

  /* DOCUMENTS */
  ALL_DOC: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/0/100`,
  UPLOAD_DOC:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/storage/upload`,
  DOWNLOAD_DOC: (id, name) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/documents/storage/download/${id ? id : ""}/${
      name ? "?originalName=" + name : ""
    }`,
  VIEW_DOWNLOAD_DOC: (id) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/documents/storage/systemname/${id ? id : ""}`,
  DELETED_DOC: (id) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/documents/storage/delete/${id ? id : ""}`,
  DOCUMENT_BY_OPERATION_BY_EXP: (id, idOperation) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/documents/storage/?expedient=${id}&operationType=${idOperation}`,
  NOTES_BY_RECORD: (id, page, size = 5) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/expedients/${id}/${page}/${size}`,
  SAVE_NOTES_BY_RECORD: (id) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/expedients/${id}/notes/`,
  UPD_NOTES_BY_RECORD: (idExp, idNote) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/expedients/${idExp}/notes/${idNote}`,
  DELETE_NOTES_BY_RECORD: (id) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/expedients/notes/${id}`,

  /**Notification */
  NOTIFICATION_ACC: (name, role) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/notification/completeExpedient?name=${encodeURI(name)}&roles=${role}`,

  /**Api Parval */
  REQUEST_RECORD_ID:
    CONSTANT.DISPATCHER_EXTERNAL_PREFIX + `/customer-operations`,
  /* REQUEST_TRANSACTCONESKO: (endpointName, page, size, columnOrder, sort) => CONSTANT.DISPATCHER_EXTERNAL_PREFIX + `/${endpointName}${endpointName !== "venc-mt" ? "?page=" + page + "&size=" + size : ""}`, */
  REQUEST_TRANSACTCONESKO: (endpointName, page, size, columnOrder, sort) =>
    CONSTANT.DISPATCHER_EXTERNAL_PREFIX +
    `/${endpointName}?page=${page}&size=${size}${
      columnOrder !== "" ? "&sort=" + columnOrder + "," + sort : ""
    }`,
  REQUEST_CUSTOMER_RIF: (id, cual) =>
    CONSTANT.DISPATCHER_EXTERNAL_PREFIX + `/idm-rif?id=${id}&cual=${cual}`,

  //https://parval-op-dispatcher.bitcode-enterprise.dev/v1/externalservices/external-service/idm-rif?id=CRU056&cual=I%27
};
