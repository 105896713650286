import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { properties } from "../../utils/Properties_es";
import { validateFieldStyle } from "../../utils/UtilsFunction";
import CustomModal from "./CustomModal";
import { useApp } from "../../hooks/useApp";
import { SaveNoteRequest } from "../../services/record/RecordService";

const AddObservation = (props) => {
  const {
    authInfo,
    isLoading,
    setLoading,
    errorMsg,
    setErrorMsg,
    setSuccessMsg,
    modalData,
    setModalData,
  } = useApp();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: props.form,
    mode: "onChange",
  });
  const [applyStyleForm, setApplyStyleForm] = useState(false);

  const applyStyleToForm = () => {
    if (!applyStyleForm) {
      setApplyStyleForm(true);
    }
  };

  const onSubmit = async (data) => {
    await props.handleAddObservation(data);
  };

  return (
    <>
      <CustomModal defaultmodal={false}>
        <Modal
          show={props.isOpen}
          keyboard={false}
          size={props.modalSize}
          onHide={() => false}
          centered
        >
          <Modal.Header>
            <Modal.Title>Agregar Observación</Modal.Title>
          </Modal.Header>
          <form /* onSubmit={handleSubmit(props.onSubmit)} */>
            <Modal.Body>
              <div className="form-group mb-2">
                <label htmlFor="name">Descripción</label>
                <input
                  type="text"
                  className={`form-control ${validateFieldStyle(
                    applyStyleForm,
                    errors.name
                  )}`}
                  maxLength={150}
                  name="description"
                  id="description"
                  {...register("description", {
                    required: true,
                  })}
                />
                {errors.description && (
                  <div className="invalid-feedback">
                    {properties.com_parval_label_field_required}
                  </div>
                )}
              </div>
              <input
                name="expedientId"
                type="hidden"
                {...register("expedientId")}
                value={props.wId}
              />
              <input
                name="createdBy"
                type="hidden"
                {...register("createdBy")}
                value={authInfo?.username}
              />
            </Modal.Body>
            <Modal.Footer>
              {/* {props.actions} */}
              <div className="row">
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button
                    className="btn btn-warning px-3 me-md-2"
                    id="actionModal"
                    onClick={handleSubmit(onSubmit)}
                    name="addObservation"
                  >
                    Agregar
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary"
                    id="cancelModal"
                    onClick={props.handleCancelModal}
                  >
                    {properties.com_parval_label_button_cancel_title}
                  </button>
                </div>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      </CustomModal>
    </>
  );
};

export default AddObservation;
