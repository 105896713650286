import {
  Dialog,
  DialogContent,
  DialogTitle,
  styled,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontWeight: 400,
  fontSize: "17.5px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(2, 4),
  marginBottom: theme.spacing(5),
  fontFamily: "D-DIN-DINExp",
}));

const CustomDialog = ({ children, title, open, handleClose, ...rest }) => {
  return (
    <Dialog onClose={handleClose} open={open} {...rest}>
      <Title id="alert-dialog-title">
        {title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon
            sx={{
              fontSize: 30,
            }}
          />
        </IconButton>
      </Title>
      <DialogContent sx={{ pt: 4, px: 4, pb: 0 }}>{children}</DialogContent>
    </Dialog>
  );
};

export default CustomDialog;
